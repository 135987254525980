import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow,
Checkbox} from '@material-ui/core'
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs, FormControlLabel,Switch, Fab} from '@material-ui/core'
import {Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Cached';
import ConexaoInventory from  '../../providers/ConexaoInventory'
import path from 'path';
import IconeDesktop from './imagens/iconeDesktop.png'
import IconeNote from './imagens/iconeNotebook.png'
import IconeVM from './imagens/iconeVirtualMachine.png'
import { FilterOutline } from 'react-ionicons'
import ModalFiltros from './SitebarFiltroInventory';
import ModallFull from '../../components/ModalFull';
import MapView from './Mapas/MapView';
import LocalizaIcon from '@material-ui/icons/Place'


const CadastroComputadoresInventory = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();
    const [inventorys, setInventorys]= useState([])
    const [inventorysFiltrados, setInventorysFiltrados]= useState([])
    const [mostrarInativos, setMostrarInativos] = useState(true)

    const [razaoSocial, setRazaoSocial]= useState('')
    const [modelo, setModelo] = useState('')
    const [enderecoIP, setEnderecoIP] = useState('')
    const [sistemaOperacional, setSistemaOperacional] = useState('')
    const [marca, setMarca] = useState('')
    const [chaveInstalacao, setChaveInstalacao] = useState('')
    const [showModal, setShowModal]= useState(false)

    const [pat, setPat]= useState('')


    const renderTipo = (tipo) =>{
        switch(tipo){
            case 'Desktop': return <img src ={IconeDesktop} width={30} />;
            case 'Notebook': return <img src ={IconeNote}  width={30} />;
            case 'Virtual Machine': return <img src ={IconeVM}  width={30} />;
            default:return <img src ={IconeDesktop} width={30}/>;
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(()=>{
        getDcas();
    }, [page])

    useEffect(()=>{
        setInventorysFiltrados(inventorys)
    }, [inventorys])


    const getDcas = () =>{
        
        setLoading(true)
        ConexaoInventory.get('InventoryController/getShortedAll?idusuario=1&idempresa='+props.location.state.empresa.idempresa+'&idcliente=1').then( response =>{
            if(response.data.status == 'OK'){
                setInventorys(response.data.payload)
               
            }else{
             
                setInventorys([])
            }
            setLoading(false)
        }).catch( error =>{
       
            setInventorys([])
            setLoading(false)

        });

    }


    const renderSignatureMobile = () =>{

    }


   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <PersonIcon color='primary' />
        }else{
            return <SettingsApplicationsIcon  color='primary'/>
        }
    }

    const renderData = (data) =>{
        var teste = data.split('T')
        return teste[0]+' '+teste[1].substr(0,8)
    }


    const searchEquipmento = (pChave = "", pRazaoSocial = "", pMarca = "", pModelo = "", pSistemaOperacional = "", pPatrimonio = "") => {
        // Verifica se o array de registros existe e é um array válido
        if (!Array.isArray(inventorys)) {
            console.log("Data is not an array or is undefined.");
            return [];
        }
    
        // Converte os parâmetros de busca para letras minúsculas e remove espaços em branco
        const searchChave = pChave.toLowerCase().trim();
        const searchRazaoSocial = pRazaoSocial.toLowerCase().trim();
        const searchMarca = pMarca.toLowerCase().trim();
        const searchModelo = pModelo.toLowerCase().trim();
        const searchSistemaOperacional = pSistemaOperacional.toLowerCase().trim();
        const searchPatrimonio = pPatrimonio.toLowerCase().trim();
    
        // Filtra os registros com base nos critérios fornecidos
        const temp = inventorys.filter(dca => {
         
            const dcaChaveInstalacao = (dca._id || "").toLowerCase().trim();
            const dcaRazaoSocial = (dca.razaoSocial || "").toLowerCase().trim();
            const dcaMarca = (dca.Modelo?.Manufacturer || "").toLowerCase().trim();
            const dcaModelo = (dca.Modelo?.Model || "").toLowerCase().trim();
            const dcaSistemaOperacional = (dca.OperatingSystem?.Name || "").toLowerCase().trim();
            
            
    
            return (
                dcaChaveInstalacao.includes(searchChave) &&
                dcaMarca.includes(searchMarca) &&
                dcaModelo.includes(searchModelo) &&
                dcaRazaoSocial.includes(searchRazaoSocial) &&
                dcaSistemaOperacional.includes(searchSistemaOperacional) 
            );
        });
    
        // Atualiza a lista filtrada
        setInventorysFiltrados(temp);
    };
    


    const  calcularDiferenca = (dataEntrada, reduzido = false) => {
        // Converte a data de entrada para o objeto Date
        const data1 = new Date(dataEntrada);
        const dataAtual = new Date(); // Data atual    
        // Calcula a diferença em milissegundos
        const diffMilissegundos =  dataAtual - data1;    
        // Convertendo a diferença
        const diffSegundos = Math.floor(diffMilissegundos / 1000);           // Segundos
        const diffMinutos = Math.floor(diffSegundos / 60);                   // Minutos
        const diffHoras = Math.floor(diffMinutos / 60);                      // Horas
        const diffDias = Math.floor(diffHoras / 24);                       // Dias    
        // Calculando a diferença exata em cada unidade
        const diasRestantes = diffDias;
        const horasRestantes = diffHoras % 24;
        const minutosRestantes = diffMinutos % 60;
        const segundosRestantes = diffSegundos % 60;
    
        // Retorna o resultado como uma string formatada
        console.log(`Diferença: ${diasRestantes} dias, ${horasRestantes} horas, ${minutosRestantes} minutos e ${segundosRestantes} segundos`)
        var ativo = true;
             
        return  diasRestantes+'-'+horasRestantes 
    }

    const  renderClasse = (dataEntrada) => {
        // Converte a data de entrada para o objeto Date
        const data1 = new Date(dataEntrada);

        const dataAtual = new Date(); // Data atual    
        // Calcula a diferença em milissegundos
        const diffMilissegundos =  dataAtual - data1;    
        // Convertendo a diferença
        const diffSegundos = Math.floor(diffMilissegundos / 1000); 
                  // Segundos
        const diffMinutos = Math.floor(diffSegundos / 60);    
                       // Minutos
        const diffHoras = Math.floor(diffMinutos / 60); 
                             // Horas
        const diffDias = Math.floor(diffHoras / 24);                        // Dias    
        // Calculando a diferença exata em cada unidade
        const diasRestantes = diffDias;

        const horasRestantes = diffHoras % 24;

        const minutosRestantes = diffMinutos % 60;

        const segundosRestantes = diffSegundos % 60;    
        // Retorna o resultado como uma string formatada
        console.log(`Diferença: ${diasRestantes} dias, ${horasRestantes} horas, ${minutosRestantes} minutos e ${segundosRestantes} segundos`)     

        if(diasRestantes > 1){

            return "dca-status-error";

        }else if(horasRestantes >= 6){

            return "dca-status-warning";
            
        }else{

            return "dca-status-default";

        }       
    }
    
    return <Principal atualizar = {loading} getData={getDcas} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Inventory Computadores
            </Link>                
        </Breadcrumbs>
    }>
        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}></Grid>
                <Grid item xs = {5}>                    
                </Grid>
            </Grid>
        </div>        
      
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'20px'}}>
           <Grid item xs = {10}>
           <FormControlLabel 
                control={<Checkbox checked = {mostrarInativos} onChange={(e)=>setMostrarInativos(!mostrarInativos)}/>} label="Mostrar desabilitados"/>
           </Grid>
           <Grid item xs = {2}>
                <Button onClick={()=>setShowModal(!showModal)}>
                        <LocalizaIcon/> <Typography variant='caption'>Ver Mapa</Typography>
                </Button>
            </Grid>
        </Grid>
        
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'10px'}}>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <ModalFiltros 
                marca = {marca}
                setMarca = {setMarca}
                modelo = {modelo}
                setModelo = {setModelo}
                razaoSocial = {razaoSocial}
                setRazaoSocial = {setRazaoSocial}
                sistemaOperacional ={sistemaOperacional}
                setSistemaOperacional = {setSistemaOperacional}
                chaveInstalacao = {chaveInstalacao}
                setChaveInstalacao ={setChaveInstalacao}
                enderecoIP ={enderecoIP}
                setEnderecoIP ={setEnderecoIP}
                pat = {pat}
                setPat = {setPat}
                serchData = {(op)=>{
                    searchEquipmento(chaveInstalacao,  razaoSocial, marca, modelo, sistemaOperacional, pat)                   
                }}
                resetFilter ={()=>{
                    searchEquipmento('',  '', '', '', '')
                }}
            />
        </Grid>

        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>{colunaFiltro.toString().toUpperCase()}</Typography></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                value={razaoSocial}
                onChange={(e)=>setRazaoSocial(e.target.value)}
               
            />
        </Grid>

        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{                              
                getDcas()                 
            }}><SearchIcon/></Button></Tooltip>
        </Grid>

        </Grid>
       <div className='div-form-container'>
        <Table className='table table-striped table-hover table-sm table-small table-hovered'>
            <TableHead>
                <TableCell align='center' style={{width :'10%'}}>#</TableCell>
                <TableCell style={{width :'15%'}}>Equipamento</TableCell>
                <TableCell style={{width :'25%'}}>Cliente</TableCell>
                <TableCell style={{width :'15%'}}>Sistema Operacional</TableCell>
                <TableCell align='center' style={{width :'10%'}}>Dominio</TableCell>         
                <TableCell align='center' style={{width :'12%'}}>Data Coleta</TableCell>
                <TableCell align='center' style={{width :'8%'}}>Habilitado</TableCell>
            </TableHead>
            <TableBody>

            {

                    inventorysFiltrados ? inventorysFiltrados.map(function(inventory, i){                                           
                        return 1 == 1 ? <TableRow  >
                                <TableCell align='center' className={renderClasse(inventory.dataAtualizacao)}>
                                    <div>
                                 
                                    <span style={{fontSize :'10px'}}>{inventory.hostName ? inventory.hostName : '* não disponível'}</span>
                                    </div>
                                    {
                                        renderTipo(inventory.typeDevice)
                                    }
                                    <div>
                                        <span style={{fontSize :'10px'}}>{inventory.typeDevice ? inventory.typeDevice : '* não disponível'}</span>
                                    </div>
                                    
                                </TableCell>
                                <TableCell style ={{verticalAlign : 'middle'}}>
                                    {
                                        !inventory.coletaAtiva || inventory.coletaAtiva =='t' ? 
                                    
                                    <Link to={{pathname : '/DataInventoryDetails', state : {
                                            empresa : props.location.state.empresa,
                                            usuario : props.location.state.usuario,  
                                            idcliente : inventory.idcliente, 
                                            inventory :inventory, 
                                            dias : calcularDiferenca(inventory.dataAtualizacao )
                                        }}}>
                                           
                                            <div>
                                                <Typography variant='subtitle2' >
                                                { inventory.modeloAlternativo ? inventory.modeloAlternativo : (inventory.Modelo ? inventory.Modelo.Model : '* não disponível')}
                                                </Typography>                                    
                                            </div>
                                            <div>
                                                <Typography variant='caption' style={{fontSize :'10px'}}>
                                                    PAT:{inventory.patrimonio  ? inventory.patrimonio : '* não disponível'}
                                                </Typography>                                    
                                            </div>
                                    </Link>
                                    : <React.Fragment>
                                            <div>
                                                <Typography variant='subtitle2' >
                                                { inventory.modeloAlternativo ? inventory.modeloAlternativo : (inventory.Modelo ? inventory.Modelo.Model : '* não disponível')}
                                                </Typography>                                    
                                            </div>
                                            <div>
                                                <Typography variant='caption' style={{fontSize :'10px'}}>
                                                    PAT:{inventory.patrimonio  ? inventory.patrimonio : '* não disponível'}
                                                </Typography>                                    
                                            </div>
                                        </React.Fragment>}
                                </TableCell>
                                <TableCell style ={{verticalAlign : 'middle'}}>
                                    <Typography variant='subtitle1'>{inventory.razaoSocial ? inventory.razaoSocial : '* não disponível'}</Typography>
                                    <Typography variant='caption' > S/N: <strong>{inventory.Bios ? inventory.Bios.SerialNumber : '* não disponível'}</strong></Typography>
                                </TableCell>
                                <TableCell style ={{verticalAlign : 'middle'}}>
                                    <div>
                                    <Typography variant='caption'>{inventory.OperatingSystem.Name ? inventory.OperatingSystem.Name : '* não disponível'}</Typography>
                                    </div>                                    
                                </TableCell>
                                <TableCell align='center' style ={{verticalAlign : 'middle'}}>{inventory.domainName ? inventory.domainName : '* não disponível'}</TableCell>                               
                                <TableCell align='center' style ={{verticalAlign : 'middle'}}>{inventory.dataAtualizacao ? renderData(inventory.dataAtualizacao) : '* não disponível'}</TableCell>
                                <TableCell align='center' style ={{verticalAlign : 'middle'}}>
                                    {
                                        inventory.coletaAtiva =='t' ? <span style={{border : 'solid 1px #009688', color:"#009688", backgroundColor : "#fff", paddingLeft : '5px', paddingRight : '5px', borderRadius : '5px'}}>SIM</span>
                                        :
                                        <span style={{border : 'solid 1px #E53935', color:"#E53935", backgroundColor : "#fff", paddingLeft : '5px', paddingRight : '5px', borderRadius : '5px'}}>NÃO</span>
                                    }                                    
                                </TableCell>                               
                    </TableRow> : null
                    }) : null
                }
            </TableBody>
        </Table>
        
        </div>                  
        <div className='fabIncluir'>
             
        </div>
      
        <ModallFull setShowModal={()=>setShowModal(!showModal)} showModal={showModal}>
            <MapView positions = {inventorysFiltrados}/>
        </ModallFull>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroComputadoresInventory)