import { Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default class Example extends PureComponent {
    constructor(props) {
        super(props);
        
      }
  static demoUrl = 'https://codesandbox.io/p/sandbox/synchronized-line-charts-37rhmf';

 
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Typography variant='subtitle1'>Histórico de Uso (Últimas 24 horas)</Typography>

        <ResponsiveContainer width="100%" height={200} style ={{marginTop :'5px'}}>
          <LineChart   
            data={this.props.data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />         
            <YAxis />
            <Tooltip  formatter={(value) => value.toFixed(2)+'%'} />           
            <ReferenceLine y={75} stroke="red" strokeDasharray="2.4"/>
            <Line type="monotone" dataKey="cargaProcessador" stroke="#00695C" fill="#8884d8" name='CPU'/>
            <Line type="monotone" dataKey="cargaMemoria" stroke="#283593" fill="#8884d8" name='RAM'/>
          </LineChart>
          
        </ResponsiveContainer>
        <div style ={{width : '100%',  display : 'flex', justifyContent:'center', paddingTop :'10px'}}>
          <span style ={{paddingLeft : '3px', paddingRight : '3px', borderRadius : '3px', fontSize:'10px', background : '#00695C', color:'#fff'}}>PROCESSADOR</span>
          <span style ={{paddingLeft : '3px', paddingRight : '3px', borderRadius : '3px', fontSize:'10px', background : '#283593', color:'#fff', marginLeft : '5px'}}>MEMÓRIA</span>
          <span style ={{paddingLeft : '3px', paddingRight : '3px', borderRadius : '3px', fontSize:'10px', background : '#fff', color:'#f00', border:'dashed 1px', marginLeft : '5px'}}>LIMITE CRÍTICO</span>
        </div>

        
      </div>
    );
  }
}