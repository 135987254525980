import React, {useState, useEffect, useRef, Fragment} from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Button, Divider, Grid, Typography, Tooltip, TextField} from '@material-ui/core'
import ModalMonitorIndividual from '../pages/ContadoresAoVivo/ModalMonitoramentoIndividual'
import Swal from 'sweetalert2'
import LockIcon from '@material-ui/icons/Lock'
import Close from '@material-ui/icons/Close'
import DetailIcon from '@material-ui/icons/Apps'



export default function SideBarFiltros(props){

    const [open, setOpen] = useState(false)
    const [chave, setChave] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [dataCriacao, setDataCriacao] = useState('')
    const [dataAtivacao, setDataAtivacao] = useState('')
    const [dataAtualizacao, setDataAtualizacao] = useState('')
    const [enderecoIP, setEnderecoIP] = useState('')

    const toggleDrawer = () =>{
        setOpen(!open)
    }

    return <div>
    <React.Fragment >
       
        <Drawer
             
          disableEscapeKeyDown
          anchor={'right'}
          open={props.openSideBar}
          hideBackdrop
          onClose={()=>props.setOpenSidebar()}
        >
            <div style ={{width: "100%", height : '67px', backgroundColor:"#37474F"}}></div>
            <div style ={{width: "100%",  backgroundColor:"#CFD8DC", textAlign:'center', display: 'flex', padding : '10px'}}>
                <div style={{width : '80%', textAlign:'left', alignContent : 'flex-start'}}>
                  
                </div>
                <div style={{width : '20%'}}>
                    <Button fullWidth onClick={()=>props.setOpenSidebar()}><Close/></Button>
                </div>
            </div>
          <div id='div-side-menu' style ={{
            width : '600px', 
            padding : '30px', 
            
          }}>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Chave de Instalação'
                            fullWidth
                            value={chave}
                            onChange={(e)=>setChave(e.target.value)}
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Cliente'
                            value={razaoSocial}
                            onChange={(e)=>setRazaoSocial(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Data Criação'
                            value={dataCriacao}
                            onChange={(e)=>setDataCriacao(e.target.value)}
                            fullWidth
                            type='date'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Data Instalação'
                            value={dataAtivacao}
                            onChange={(e)=>setDataAtivacao(e.target.value)}
                            fullWidth
                            type='date'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Data Última coleta'
                            value={dataAtualizacao}
                            onChange={(e)=>setDataAtualizacao(e.target.value)}
                            fullWidth
                            type='date'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing = {4}>
                    <Grid item xs = {12}>
                        <TextField
                            label='Endereço IP'
                            value={enderecoIP}
                            onChange={(e)=>setEnderecoIP(e.target.value)}
                            fullWidth
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            size ='small'
                        />
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
                <Grid container spacing = {1}>
                    <Grid item xs = {8}>
                        <Button size='large'  fullWidth variant='contained' color='primary' onClick={()=>{
                            props.searchEquipmento(chave, enderecoIP, razaoSocial, dataCriacao, dataAtivacao, dataAtualizacao)
                            
                        }}>Aplicar</Button>
                    </Grid>
                    <Grid item xs = {4}>
                        <Button size='large' fullWidth variant='contained' color='secondary' onClick={()=>{
                            setChave('')
                            setDataCriacao('')
                            setDataAtivacao('')
                            setDataAtualizacao('')
                            setEnderecoIP('')
                            setRazaoSocial('')
                            props.searchEquipmento('','','','','','')
                            props.setOpenSidebar()
                        }}>Limpar</Button>
                    </Grid>
        
                </Grid>

                <Grid container spacing = {2} style={{marginTop:'3px'}}>

                    <Grid item xs = {12}>
                        <Button size='large'  fullWidth variant='contained' color='default' onClick={()=>props.setOpenSidebar()}>Fechar</Button>
                    </Grid>
                </Grid>
           
          </div>
        </Drawer>
      </React.Fragment>
  </div>
}
