import React , {useState, useEffect, useRef} from 'react'
import {withRouter} from 'react-router-dom'

import ReactShadowScroll from 'react-shadow-scroll';
import BotaoSideMenu from './Compontentes/BotaoSideMenu';
import IconDash from '@material-ui/icons/PieChartOutlined'
import IconCollector from '@material-ui/icons/UsbOutlined'
import IconPrintLogger from '@material-ui/icons/VerifiedUserOutlined'
import IconOrdemServico from '@material-ui/icons/BuildOutlined'
import { Badge, Divider, Typography } from '@material-ui/core';
import ItemSubMenu from './Compontentes/ItemSubMenu';
import IconSuprimentos from '@material-ui/icons/ShoppingCartOutlined'
import IconUser from '@material-ui/icons/VpnKeyOutlined'
import {Breadcrumbs, LinearProgress, Backdrop, CircularProgress } from '@material-ui/core'
import {Link } from 'react-router-dom'
import LogoDatacount from './logo-datacount.png'
import LogoPrinterconnect from './logoPrinterConnect.png'
import DashboardIcon from '@material-ui/icons/PieChartOutlined'
import UserIcon from '@material-ui/icons/PeopleAltOutlined'
import CollectorIcon from '@material-ui/icons/UsbOutlined'
import OrdemServicoIcon from '@material-ui/icons/BuildOutlined'
import RequisicoesIcon from '@material-ui/icons/PhoneOutlined'
import PrintLoggerIcon from '@material-ui/icons/VerifiedUserOutlined'
import IconDCA from '@material-ui/icons/AppsOutlined'
import IconColetas from '@material-ui/icons/PrintOutlined'
import IconAlertToner from '@material-ui/icons/ReportProblemOutlined'
import IconComputadores from '@material-ui/icons/ComputerOutlined'
import IconFilas from '@material-ui/icons/PrintOutlined'
import IconSetores from '@material-ui/icons/AccountBalanceOutlined'
import IconLogImpressao from '@material-ui/icons/Dehaze'
import IconRelatorios from '@material-ui/icons/AssignmentOutlined'
import { fade, makeStyles } from '@material-ui/core/styles';
import {logout  } from '../../providers/Autenticate';
import  ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SelecionaEmpresaAtiva from '../../components/SelecionaEmpresaAtiva'
import SearchIcon from '@material-ui/icons/Search';
import BlockIcon from '@material-ui/icons/Block';
import Gesture from '@material-ui/icons/Gesture';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Build from '@material-ui/icons/Build';
//import logoDatacount from '../LandingPage/Componentes/logo-databit.png'
import Memory from '@material-ui/icons/Memory';
import Notifications from '@material-ui/icons/Notifications';
import ReportIcon from '@material-ui/icons/Assignment'
import Loop from '@material-ui/icons/Loop';
import RepeatOne from '@material-ui/icons/RepeatOne';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import TagFaces from '@material-ui/icons/Apps'
import IconDcas from '@material-ui/icons/FindInPage'
import {Button} from '@material-ui/core';
import Swal from 'sweetalert2';
import CloseIcon from '@material-ui/icons/Close'
import IconeSemColeta from '@material-ui/icons/WifiOff'
import ClienteAtivo from '@material-ui/icons/Domain';
import GetAppIcon from '@material-ui/icons/GetApp';
import Conexao from '../../providers/Conexao'
import VPNIcon from '@material-ui/icons/VpnKey';
import ComputerOutlined from '@material-ui/icons/ComputerOutlined';

let timer = null;
var varUsuario =null;
var varEmpresa = null;

const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
      padding : 0
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      fontWeight:'normal!important',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      paddingLeft:'0px',
      paddingRight:"10px", 
      paddingLeft : '70px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));
  const handleLogoff = () =>{
    logout();

    window.location.href ='/'
  }


const TemplateRevenda = (props)=>{
    let url = window.location.host;
    const [logoEmpresa, setLogoEmpresa] = useState(url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'serverDatacount': 'serverPrinterconnect' );
  
    const [selected, setSelected] = useState(false);
    const [resolucao, setResolucao] = useState(window.screen.height > 740 ? 24 : 12)
    const [dataBase, setDataBase] = React.useState(url == 'datacount.com.br' || url == 'www.datacount.com.br' ? 'Datacount' : 'printerconnect');
    const [expanded, setExpanded] = React.useState('');
    const [empresa, setEmpresa] = React.useState([]);
    const [empresaAtiva, setEmpresaAtiva] = React.useState([]); 
    const [moduloCollector, setModuloCollector] = React.useState(false)
    const [moduloinventory, setModuloinventory] = React.useState(false)
    const classes = useStyles();

    React.useEffect(()=>{

        const emp = sessionStorage.getItem('empresa@printerConnect', []);
     

        const empAtiva = sessionStorage.getItem('empresaAtiva@printerConnect', null);

        setEmpresa(JSON.parse(emp))
        const collectorTemp = sessionStorage.getItem('habilitarcollector', [])
        const inventoryTemp = sessionStorage.getItem('habilitarinventory	', [])
        setModuloCollector(collectorTemp =='t');
        setModuloinventory(inventoryTemp =='t');
        

        
      if(empAtiva){
           
          setEmpresaAtiva(JSON.parse(empAtiva))

      }else{

          setEmpresaAtiva({idempresa:-1, razaosocial:''})
          
      }
        
      }, [props.location])
    
    const handleChange = (value) =>{
        if(value !==expanded){
            setExpanded(value)
        }else{
            setExpanded('')
        }
        
    }

    React.useEffect(()=>{
        const emp = sessionStorage.getItem('empresa@printerConnect', []);
        const empAtiva = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect', null));
       
        varEmpresa = empAtiva ? empAtiva.idempresa : -1;
        varUsuario = empAtiva ? empAtiva.idusuario : -1;
        setEmpresa(JSON.parse(emp))
        if(empAtiva){
          setEmpresaAtiva(empAtiva)
      }else{
          setEmpresaAtiva({idempresa:'', razaosocial:''})
          
      }
     // getData(); 
      }, [])

      React.useEffect(()=>{
       /* timer = setInterval(()=>{
            //getData()
        }, 600000)*/

        return ()=> clearInterval(timer);
      }, [])


      const getData = (periodoDias = 0) =>{

        var horaInicial = sessionStorage.getItem('timeUltimaLeitura');
       

        if(horaInicial){
            
            let diferencaEmMinutos = calcularDiferencaEmMinutos(new Date(horaInicial));
            
            if(diferencaEmMinutos >= 30){
                sessionStorage.setItem('timeUltimaLeitura',new Date() )
                buscarDadosAPI();
               

            }

            

        }else{
            sessionStorage.setItem('timeUltimaLeitura',new Date() )
            buscarDadosAPI();
            
        }
  
    }

    const buscarDadosAPI = () =>{
        Conexao.post('coletasController/getContadoresData', {
            idempresa : varEmpresa,            
            idcliente : null,            
            dias : 0,
        }).then(response=>{

            if(response.data.status == 'OK'){

                let temp = response.data.payload;

                for(let i = 0; i < temp.length; i++){

                    temp[i].dias = calcularDiferencaDias(temp[i].datacoleta+" "+temp[i].horacoleta.substr(0,8));
                  //  temp[i].diasCadastro = calcularDiferencaDiasCadastro(temp[i].datacadastro+" 00:00:00", "horas");

                }

               
                
                sessionStorage.setItem('equipamentos', JSON.stringify(temp));
                
                
            }else{
              
            }

           

        }).catch(error =>{
            sessionStorage.removeItem('equipamentos');
         
        });
    }

    const calcularDiferencaEmMinutos = (dataInicio) =>{
        // Calcule a diferença em milissegundos

        var dataFim = new Date();  // Isso usa a data e hora atuais
       
        var diferencaEmMilissegundos = Math.abs(dataFim - dataInicio);
      
        // Converta a diferença de milissegundos para minutos
        var diferencaEmMinutos = Math.floor(diferencaEmMilissegundos / 60000);
     
   
        return diferencaEmMinutos;
      }

    const calcularDiferencaDias = (data, tipo = "dias") =>{
        
        const dataHoraColetaString = data;
        const dataHoraColeta = new Date(dataHoraColetaString); // Converter a string para um objeto Date

        // Obter a data e hora atual
        const dataHoraAtual = new Date();

        // Calcular a diferença em milissegundos
        const diferencaEmMilissegundos = dataHoraAtual - dataHoraColeta;

        // Converter a diferença em dias
        
        const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24) ;
     
        return  Math.round(diferencaEmDias)
    }

      const setarEmpresaAtiva = (emp) =>{
        setEmpresaAtiva(emp)
      }



      const handleLogoff = () =>{
        logout();
   
        window.location.href ='/'
      }


    
    const cliente = {
        "idcliente": 2,
        "idusuario": 4,
        "idempresa": 3,
        "razaosocial": "CENTERMAQ MARILIA",
        "nomefantasia": "CENTERMAQ MARILIA",
        "chavecoleta": "DB00650512000101-3-2",
        "chavebilling": null,
        "chavetraking": null,
        "cpf_cnpj": "62.771.316\/0001-34",
        "rg_insc": "",
        "logradouro": "Rua Narciso Ribeiro",
        "numero": 319,
        "complemento": "teste",
        "bairro": "Jardim Nacional",
        "cep": 17523230,
        "cidade": "Mar\u00edlia",
        "estado": "SP",
        "telefonecomercial": "(14) 3311-7888",
        "telefonecelular": "(17) 49991-1411",
        "email": "contato@centermaq.com",
        "ativo": "t",
        "idclienteinterno": 0,
        "equipamentos": 56
    }

    return <div id='template-home-container' >
        <div id='side-menu-main' className='background-sideMenu-Revenda'>
            <div id='side-menu-logo'>
                <img src={dataBase =='Datacount' ? LogoDatacount : LogoPrinterconnect} />
            </div>
            <div id='side-menu-container'>
                <div>
                
                </div>
            <ul id="nav" >
                <li >
                    <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/grafico-clientes' : '', state :{
                                            empresa : empresaAtiva, 
                                            }}}>
                       
                            <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconDash style={{color:'#fff'}} /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem'  >Dashboard</Typography></div>
                            </div>
                    </Link>
                </li>
                {
                    empresa.idusuariorevenda == null || empresa.idusuariorevenda == null ?
                <React.Fragment>
                <Divider/>
                
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><AccountTreeIcon style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' >Empresas</Typography></div>
                        </div>
                    
                    </a>
                <ul>
                    <li>
                        <Link  to ={{pathname : '/minheempresa', state :{
                                        empresa : empresaAtiva, 
                                        }}}>
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><UserIcon /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Meus Dados</Typography></div>
                            </div>
                        </Link>
                    </li>
                    <Divider/>
                    
                    <li>
                        <Link  to ={{pathname : '/cadastro-empresa', state :{
                                            empresa : empresa, 
                                            }}}>
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><ClienteAtivo /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Cadastro Empresas</Typography></div>
                            </div>
                        </Link>
                    </li>
                  
                    
                    
                    </ul>
                </li>
                
                <Divider/>
                <li >
                    <Link to ={{
                                    pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/usuarios-revenda':'', state:{
                                    empresa : empresaAtiva, 
                                    }}}>
                       
                            <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><VPNIcon style={{color:'#fff'}} /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem'  >Usuários</Typography></div>
                            </div>
                    </Link>
                </li>
                </React.Fragment>
                :null
                }
                <Divider/>

                {
                    empresa.clientes == 't' || empresa.idusuariorevenda == null ?
                <React.Fragment>
                <li >
                    <Link to ={{
                                    pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/cadastro-clientes':'', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                       
                            <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><GroupAddIcon style={{color:'#fff'}} /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem'  >Clientes</Typography></div>
                            </div>
                    </Link>
                </li>
                <Divider/>
                </React.Fragment>: null}
                {
                        empresa.idusuariorevenda == null || empresa.collector =='t' ?
                 <React.Fragment>
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconCollector style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' >Collector</Typography></div>
                        </div>
                    
                    </a>
                <ul>


                    {
                        (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 && (empresaAtiva.idempresa == 3 || empresaAtiva.idempresa == 31 || empresaAtiva.idempresa == 2  )  && empresa.idusuariorevenda == null) ?
                    
                    <React.Fragment>
                    <li>
                    <Link   
                        to ={{pathname : (empresaAtiva.ativa =='t' &&  (empresaAtiva.idempresa == 3 || empresaAtiva.idempresa == 31 ) ) ? '/homologacoes/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '', state:{
                            empresa : empresaAtiva, 
                            usuario : empresa
                            }
                        }}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><TagFaces /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Homologar</Typography></div>
                        </div>      
                    </Link>  
                    </li>
                    <Divider/>


                    <li>
                    <Link   to ={{pathname : (empresaAtiva.ativa =='t' &&  (empresaAtiva.idempresa == 3 || empresaAtiva.idempresa == 31) ) ? '/parametros_dca/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><FormatListNumbered /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Parametros de Coleta</Typography></div>
                        </div>      
                    </Link>  
                    </li>
                    <Divider/>

                   

                      </React.Fragment>  : ''}

                    

                    {
                        empresa.idusuariorevenda == null || empresa.collector =='t' ?
                    <React.Fragment>
                     <React.Fragment>
                      <li>
                    <Link    to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/licencas_collect/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconPrintLogger /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Licenças</Typography></div>
                        </div>      
                    </Link>  
                    </li>
                    <Divider/>
                    </React.Fragment>

                    <React.Fragment>

                    <li>
                    <Link   to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/dca/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconDcas /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >DCAs</Typography></div>
                        </div>      
                    </Link>  
                    </li>
                    </React.Fragment>
                    </React.Fragment>
                    : null}
                    
                    </ul>
                </li>
                <Divider/>
                </React.Fragment> : null } 
                {
                        empresa.idusuariorevenda == null || empresa.equipametos =='t' ?
                 <React.Fragment>
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconColetas style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' >Equipamentos</Typography></div>
                        </div>
                    
                    </a>
                <ul>
      
                    <li>
                    <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestaoDescobertas/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><SearchIcon /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Descobertas</Typography></div>
                        </div>                            
                    </Link>
                    </li>

                    <Divider/>
                    <li>
                    <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><CollectorIcon /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Visão de Parque</Typography></div>
                        </div>                            
                    </Link>
                    </li>


                    <Divider/>
                        <li>
                            <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/GestaoColetasOffline/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                                <div style={{display:'flex'}}>
                                    <div style ={{width : '25%', textAlign:'center'}}><IconeSemColeta /></div>
                                    <div style ={{width : '75%'}}><Typography variant='caption' >Equipamentos OffLine</Typography></div>
                                </div>      
                            </Link>  
                        </li>
                    <Divider/>
                    <li>
                    <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_desabilitados/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><BlockIcon /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Desabilitados</Typography></div>
                        </div>      
                    </Link>  
                    </li>
                    
                    <Divider/>
                    <li>
                    <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas_suprimentos/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><Notifications /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Alertas Toner</Typography></div>
                        </div>      
                    </Link>  
                    </li>

                    <Divider/>
                        <li>
                            <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/trocas_suprimento/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                                <div style={{display:'flex'}}>
                                    <div style ={{width : '25%', textAlign:'center'}}><Loop /></div>
                                    <div style ={{width : '75%'}}><Typography variant='caption' >Trocas Toner</Typography></div>
                                </div>      
                            </Link>  
                        </li>


                    <Divider/>
                        <li>
                            <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas_consumiveis/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                                <div style={{display:'flex'}}>
                                    <div style ={{width : '25%', textAlign:'center'}}><Memory /></div>
                                    <div style ={{width : '75%'}}><Typography variant='caption' >Parts</Typography></div>
                                </div>      
                            </Link>  
                        </li>
                        <Divider/>
                        <li>
                            <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/AlertasManutencao/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                                <div style={{display:'flex'}}>
                                    <div style ={{width : '25%', textAlign:'center'}}><Build /></div>
                                    <div style ={{width : '75%'}}><Typography variant='caption' > Chamadas de Serviço</Typography></div>
                                </div>      
                            </Link>  
                        </li>
                        
                   
                        <Divider/>
                    <li>
                    <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas-atolamentos' : '/404', state:{
                                    empresa : empresaAtiva, 
                                    usuario : empresa
                                    }}}>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><Gesture /></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' >Alertas de Atolamentos</Typography></div>
                        </div>      
                    </Link>  
                    </li>
                    
                    </ul>
                </li>
                <Divider/>
                </React.Fragment>       : null }
                {moduloinventory ? <React.Fragment>
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><ComputerOutlined style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Badge badgeContent = 'Breve' color='secondary' className='emBreve'><Typography variant='caption' className='itemMenuItem' >Inventory</Typography></Badge></div>
                        </div>
                    
                    </a>
                    
                    <ul>
       
                    <li>
                        <Link to={{pathname : '/inventory-dca/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1', state:{
                            empresa : empresaAtiva, 
                            usuario : empresa 
                        
                        }}}>
                       
                            <div style={{display:'flex', }}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconDcas /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >DCAs</Typography></div>
                            </div>
                        </Link>
                    </li>
                    <Divider/>
                 

                    <li>
                        <Link to={{pathname : '/CadastroComputadoresInventory', state:{
                            empresa : empresaAtiva, 
                            usuario : empresa 
                        
                        }}}>
                       
                            <div style={{display:'flex', }}>
                                <div style ={{width : '25%', textAlign:'center'}}><ComputerOutlined /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Computadores</Typography></div>
                            </div>
                        </Link>
                    </li>
                    <Divider/>
                   
                    
                    </ul>
                     
                    </li>
                <Divider/>
                </React.Fragment>
                :null}

{/*
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconPrintLogger style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' >Print Logger</Typography></div>
                        </div>
                    
                    </a>
                    <ul>
                    <li><a style ={{cursor:'pointer'}}>
                            <div style={{display:'flex', }}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconComputadores /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Licenças</Typography></div>
                            </div>
                        
                        </a></li>
                    <Divider/>
                    
                    <li>
                        <Link to={{pathname : '/webclient/equipamentos', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex', }}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconDcas /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >DCA´s</Typography></div>
                            </div>
                        </Link>
                    </li>
                    <Divider/>
                    
                    
                    </ul>
                    </li>
                <Divider/>
*/
                    }
                    {
                        empresa.relatorios =='t' || empresa.idusuariorevenda == null?
                    
                <li> <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/GestaoColetasRelatorios/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa : '/404'}}>
                       
                            <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><ReportIcon style={{color:'#fff'}} /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem'  >Relatórios</Typography></div>
                            </div>
                    </Link>
                    <Divider/>
               
                </li>
                : null
                    }

     
                

                <Divider/>
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><GetAppIcon style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' >Downloads</Typography></div>
                        </div>
                    
                    </a>
                <ul>
                    
                    <li><a  href ={'/downloads/databit-collector-setup-dbh.zip'  } target='_blank'>
                            <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><GetAppIcon /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >DCA`s</Typography></div>
                            </div>
                        
                        </a></li>
                    <Divider/>
                    <li><a  href ={"/downloads/datacount-service-dbh.zip"} target='_blank'>
                            <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><GetAppIcon /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Datacount Services</Typography></div>
                            </div>
                        
                        </a></li>
                  
                    
                    </ul>
                </li>


                {(empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 && (empresaAtiva.idempresa == 3 || empresaAtiva.idempresa == 31)) && empresa.idusuariorevenda == null ?
                <React.Fragment>
                <Divider/>
                <li><a style ={{cursor:'pointer'}}>
                        <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><VPNIcon style={{color:"#fff"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' >Gestão de Revendas</Typography></div>
                        </div>
                    
                    </a>
                <ul>
                <li>
                    <Link  to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/revendas' : '/404', state:{
                        codigoEmpresa : empresaAtiva.idempresa, 
                        codigoUsuario : empresaAtiva.idusuario
                    }}}>
                       
                       <div style={{display:'flex', paddingTop : '5px', paddingBottom  : '5px'}}>
                           <div style ={{width : '25%', textAlign:'center'}}><ReportIcon /></div>
                           <div style ={{width : '75%'}}><Typography variant='caption'   >Revendas Cadastradas</Typography></div>
                       </div>
                    </Link>
                    </li>
                    <Divider/>
                    
                  
                    
                    </ul>
                </li>
                </React.Fragment>:''
                }
                
            </ul>
            </div>
        </div>
        <div id='container-main'>
            <div id='top-nav-menu' style={{backgroundColor:'#37474F', height:'68px',  display:'flex'}}>
                
                <div style={{padding:'10px',paddingTop:'8px' }}>
                    <SelecionaEmpresaAtiva idUsuario={empresa && empresa.idusuario} empresaAtiva={empresaAtiva.idempresa} setEmpresaAtiva = {(emp)=>{
                        setarEmpresaAtiva(emp)
                        sessionStorage.removeItem('timeUltimaLeitura');
                        sessionStorage.removeItem('equipamentos');
                    }}/> 
                </div> 
                <div style={{padding: '0px', display:'flex', width:"100%"}}>

                    <div style ={{width : '90%'}}>
                        <div >
                        <Typography style={{color:'#fff', fontSize:'16px', padding:'0px!important'}} variant='caption'>{empresaAtiva.idempresa+' '+empresaAtiva.razaosocial+ ' '} </Typography>
                        </div>
                        <div className='noPadding'>
                        <Typography style={{color:'#fff', fontSize:'10px', padding:'0px!important'}} variant='caption'>Revenda : {empresa.nome} </Typography>
                        </div>
                        <div className='noPadding'>
                        <Typography style={{color:'#fff', fontSize:'9px', padding:'0px!important'}} variant='caption'>Usuário : {empresa.idusuariorevenda == null? <strong>{empresa.nomecompleto}</strong> : empresa.nomecompleto}  </Typography>
                        </div>

                        
                    </div>
                    <div style ={{width : '10%'}}>
                    <Button onClick={()=>{
                        Swal.fire({
                            title  : 'Datacount',
                            text :' Deseja realmente sair?', 
                            icon:'question', 
                            showCancelButton :true, 
                            cancelButtonText : 'Não', 
                            showConfirmButton : true, 
                            confirmButtonText :'Sim'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                handleLogoff()
                            }
                        })
                        
                    }}>
                        <CloseIcon  color='secondary'/>
                        <Typography variant='caption' color='secondary'> Sair</Typography>
                    </Button>
                    </div>
                    
                  
                </div>
            </div>
            <div id='top-nav-menu' className='background-topMenu-Revenda'>
                {
                    props.breadcrumbs ? props.breadcrumbs :  <Breadcrumbs aria-label="breadcrumb">  
                    <Typography>Você esta em:</Typography>          
                        <Link color="inherit" to={'#'} >
                            Home
                        </Link>                
                    </Breadcrumbs>
                }
            </div>
            
            {
                (props.loading  || props.atualizar) ? <LinearProgress variant='indeterminate' color='secondary' id='progress-loading'/> : ''
            }
        
            <div id='container-full'>
            
            {
                props.children 
            }
            </div>
        </div>     
      
    </div>
}



export default withRouter(TemplateRevenda)


