import React from 'react'
import { Circle, TileLayer,withLeaflet,  Popup, Marker,  Tooltip, ZoomControl, Polygon, Polyline, Map  } from "react-leaflet";
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell, TableRow, Checkbox} from '@material-ui/core'
import MeasureControlDefault from 'react-leaflet-measure'; //https://www.npmjs.com/package/react-leaflet-measure
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';

import { Icon, DivIcon } from "leaflet";
//import {Marker,} from 'react-leaflet-enhanced-marker' //https://www.npmjs.com/package/react-leaflet-enhanced-marker
import MarkerClusterGroup from "react-leaflet-markercluster";
import MarkerIcon from './icones/placeIcon2.png'
import IconeTecnico from './icones/iconeAtendenteTecnico.png'
import IconeRevenda from './icones/placeIcon2.png'
import IconeCliente from './icones/iconeCliente.png'
import IconeVM from '../../../components/imagens/iconeVirtualMachine.png'
import IconeDesktop from '../imagens/iconeDesktop.png'
import IconeNote from '../imagens/iconeNotebook.png'
import { Apps, AppsOutline,List, Search  } from 'react-ionicons' //https://react-ionicons.netlify.app/




import Swal from 'sweetalert2';


    const iconTecnico = new Icon({
      iconUrl: IconeTecnico,
      iconSize: [32, 54], 
      iconAnchor :[
          20, 30
      ], 
      popupAnchor: [0, 0]
    });

    const iconeRevenda = new Icon({
      iconUrl: IconeRevenda,
      iconSize: [48, 48], 
      iconAnchor :[
          0, 0
      ], 
      popupAnchor: [0, 0]
    });

    const iconeCliente = new Icon({
      iconUrl: IconeCliente,
      iconSize: [54, 54], 
      iconAnchor :[
          25, 0
      ], 
      popupAnchor: [0, 0]
    });


export default function (props){

    const position = [51.505, -0.09]
    const [posicaoInicio, setPosicaoInicio] = React.useState(null);
    const [dataEmpresa, setDataEmpresa]= React.useState([]);
    const [tecnicos, setTecnicos] = React.useState([]);
    const [regioes, setRegioes] = React.useState([]);
    const [chamadosAbertos, setChamadosAbertos] = React.useState([]);



    React.useEffect(()=>{
       

        const timer = setInterval(()=>{
          //  getPosicoes()
            
        }, 120000)
        
        return ()=>{
            clearInterval(timer);
        }
    },[]);

    const handlePositionLine = (posicao)=>{
     // console.log(posicao.lat)
    // setPosicaoInicio([posicao.lat, posicao.lng])
    
    }


    const getPosicoes = ()=>{
        if(props.mostrarTecnicos){
           // getPosicaoTecnicos();
        }
        if(props.mostrarChamados){
         // getChamadosAbertos();
        }
    }




    const renderTipo = (tipo) =>{
        
      switch(tipo){
          case 'Desktop': return <img src ={IconeDesktop} width={100} />;
          case 'Notebook': return <img src ={IconeNote}  width={100} />;
          case 'Virtual Machine': return <img src ={IconeVM}  width={100} />;
          default:return <img src ={iconeRevenda} width={100}/>;
      }
  }

   
  const renderData = (data) =>{
    var teste = data.split('T')
    return teste[0]+' '+teste[1].substr(0,8)
}


    return <div style ={{height: "100%", marginTop:"0px", width: "100%", }}>
      {!props.disableSearch ? 
      <div id='div-container-search-map'>
        <div id='div-search-map'>
            <div style={{width:'10%'}}>
            <List
              color={'#00000'} 
              height="30px"
              width="30px"
              className='set-options-search-map'
            />

            </div>
            <div style={{width:'80%'}}>
            <TextField 
              fullWidth        
            />
            </div>
            <div style={{width:'10%'}}>
            <Search
              color={'#00000'} 
              height="30px"
              width="30px"
            />

            </div>
        </div>
        <div id='div-search-option'>
          teste
        </div>
      </div> : null}
        <Map
          id="modalMap"                        
          animate={true}
          style={{ height: "100%", width: "100%", }}
          minZoom={5}
          zoom={props.defaultZoon ? props.defaultZoon : 9 }                        
          zoomControl={true}                                                        
          center={[props.latitude ? props.latitude : -22.2244322, props.longitude ? props.longitude : -49.8917842]}    
          onclick={(e)=>{
           handlePositionLine(e.latlng)
           //console.log(e)
          }}    
          >
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {

              props.latitude && props.longitude ? <Circle
              fillColor={"#f00"}
              stroke ={true}                
              center = {[props.latitude , props.longitude ]}
              radius = {1500}
            /> : null
            }
            
            <MarkerClusterGroup 
                maxClusterRadius={50}>
                  {
                    props.positions && props.positions.map(function(posicao, i){
                      // Verifica e corrige as coordenadas se necessário
                      let latitude = posicao.Posicao.latitude ? parseFloat(posicao.Posicao.latitude.toString().replace(',', '.')) : null;
                      let longitude = posicao.Posicao.longitude ? parseFloat(posicao.Posicao.longitude.toString().replace(',', '.')) : null;

                      return (latitude && longitude) ? (
                        <Marker 
                          key={'revenda' + i}                   
                          position={[latitude, longitude]}                                        
                          icon={iconeRevenda}
                        >
                            <Popup minWidth={600}>
                                <div style={{ display: "flex", width: '100%' }}> {/* Usando largura de 100% */}
                                  <div style={{ width: "25%", textAlign: 'center' }}> {/* Usando % para manter proporção */}
                                    <div style={{ padding: '5px', paddingBottom: '10px' }}>{posicao.hostName}</div>
                                    <div>{renderTipo(posicao.typeDevice)}</div>
                                    <div style={{ padding: '5px', paddingTop: '10px' }}>{posicao.typeDevice}</div>
                                  </div>
                                  <div style={{ width: "75%", paddingLeft: "10px" }}>
                                    <table className='table-no-border'>
                                      <tbody>
                                        <tr>
                                          <td colSpan='2'>
                                            <Typography variant='subtitle1'>
                                              <strong>{posicao.razaoSocial}</strong>
                                            </Typography>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ width: "65px" }}>
                                            <Typography variant='caption'><strong>DCA</strong></Typography>
                                          </td>
                                          <td>
                                            <Typography variant='subtitle2'>{posicao._id}</Typography>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><Typography variant='caption'><strong>Serial</strong></Typography></td>
                                          <td><Typography variant='caption'>{posicao.Bios.SerialNumber}</Typography></td>
                                        </tr>
                                        <tr>
                                          <td><Typography variant='caption'><strong>S.O</strong></Typography></td>
                                          <td><Typography variant='caption'>{posicao.OperatingSystem.Name}</Typography></td>
                                        </tr>
                                        <tr>
                                          <td><Typography variant='caption'><strong>Arquitetura</strong></Typography></td>
                                          <td><Typography variant='caption'>{posicao.OperatingSystem.Architecture}</Typography></td>
                                        </tr>
                                        <tr>
                                          <td><Typography variant='caption'><strong>Versão</strong></Typography></td>
                                          <td><Typography variant='caption'>{posicao.OperatingSystem.Version}</Typography></td>
                                        </tr>
                                        <tr>
                                          <td><Typography variant='caption'><strong>Data</strong></Typography></td>
                                          <td>
                                            <Typography variant='caption'>
                                              {posicao.dataAtualizacao ? renderData(posicao.dataAtualizacao) : '* não disponível'}
                                            </Typography>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><Typography variant='caption'><strong>Position</strong></Typography></td>
                                          <td>
                                            <Typography variant='caption'>
                                              {posicao.Posicao.latitude+', '+posicao.Posicao.longitude}
                                            </Typography>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <br />
                                  </div>
                                </div>
                            </Popup>

                        </Marker>
                      ) : null;
                    })
                  }
          </MarkerClusterGroup>   

                    
          </Map>
        </div>
  
}