import React, {useState} from 'react';
import clsx from 'clsx';
import {withRouter} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import {TextField, InputAdornment, Typography} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import LinearProgress from '@material-ui/core/LinearProgress';
import DataCountLogo from '../LandingPage/Componentes/logo-datacount.png'

var md5 = require('md5');




const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const LoginAcesso = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });
  const [loading, setLoading] = React.useState(false);
  const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [recuperarSenha, setRecuperarSenha] = useState(false)



    

 


  const autenticar = () =>{
    
    setLoading(true)
    sessionStorage.removeItem('equipamentos');
    sessionStorage.removeItem('timeUltimaLeitura');

    Conexao.post('usuariosController/auth', {
        login : login, 
        senha : md5(senha)
    }).then(response =>{
      
            if(response.data.status == 'OK'){
               
                sessionStorage.setItem('token@printerconnect', response.data.payload.idusuario);
                sessionStorage.setItem('habilitarcollector', response.data.payload.habilitarcollector);
                sessionStorage.setItem('habilitarinventory	', response.data.payload.habilitarinventory	);
                sessionStorage.setItem('BarenToken', response.data.payload.BarenToken);
                sessionStorage.setItem('empresa@printerConnect', JSON.stringify(response.data.payload));
                sessionStorage.setItem('empresaAtiva@printerConnect', JSON.stringify(response.data.payload.empresaPadrao));
                props.history.push({pathname : '/grafico-clientes', state : {
                    empresa : response.data.payload.empresaPadrao,
                    empresaPadrao : response.data.payload.empresaPadrao, 
                    dataBase : props.dataBase, 
                    habilitarcollector	: response.data.payload.habilitarcollector, 	
                    habilitarinventory	: response.data.payload.habilitarinventory, 
                  }})
            }else{
                Swal.fire({
                    title: 'Falha de Autenticação',
                    text: 'Não foi possível autenticar ! Usuário o Senha inválidos !',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                  })
            }
            setLoading(false)
        
    }).catch( error =>{
        
        Swal.fire({
            title: 'Falha de Conexão',
            text: `Não foi possível acessar recurso remoto: ${error}`,
            icon: 'error',
            confirmButtonText: 'OK'
          })
          setLoading(false)
    })
}

  return (
  
      <div key={'DRAWER01'} id='form-login' style ={{padding : 20, paddingTop : 10, paddingBottom:'10px'}}>
                    <div style={{textAlign:"center"}}>
                      <Typography variant='h5' style={{textAlign:'center'}}>AREA DA REVENDA</Typography>
                    </div>
                      <br/>
                      <br/>
                  
                    <TextField
                        variant='outlined'
                        label='Login'
                        placeholder='Informe o seu email de acesso'
                        InputLabelProps = {{shrink : true}}
                        fullWidth = {true}
                        onChange = {(e)=>setLogin(e.target.value)}
                        value ={login}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                     />
                

                {!recuperarSenha && <div style ={{marginTop:"20px"}}>
                    <TextField
                        variant='outlined'
                        label='Password'
                        placeholder='Informe a sua palavra passe'
                        InputLabelProps = {{shrink : true}}
                        fullWidth = {true}
                        type = 'password'
                        onChange = {(e)=>setSenha(e.target.value)}
                        value ={senha}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                     />
                </div>}
                <br/>
              
                <div style ={{textAlign:"right"}}>
                <div  style ={{textAlign:"left", paddingBottom:"10px"}}>
                {recuperarSenha && <Typography variant = 'caption'>* Será enviado para o email informado (se cadastrado) o link para alteração da senha.</Typography>}
                </div>
                          {recuperarSenha ? <Button variant='contained' color ='primary' onClick={()=>{
                            Swal.fire({
                              title :'Printerconnect', 
                              text : 'Deseja receber o link para recuperação de senha ?', 
                              icon : 'question',                               
                              showCancelButton : true, 
                              cancelButtonText : 'Cancelar', 
                              showConfirmButton : true, 
                              confirmButtonText : 'Enviar'
                            }).then(resposta =>{
                              if(resposta.isConfirmed){
                                setLoading(true)
                                Conexao.post('usuariosController/recuperarSenha', {
                                  login : login
                              }).then(response =>{
                                
                                      if(response.data.status == 'OK'){
                                        Swal.fire({
                                          title: 'Printerconnect',
                                          text: 'Email enviado com sucesso',
                                          icon: 'success',
                                          confirmButtonText: 'OK'
                                        })
                                         
                                      }else{
                                          Swal.fire({
                                              title: 'Printerconnect',
                                              text: 'Não foi possível enviar email',
                                              icon: 'warning',
                                              confirmButtonText: 'OK'
                                            })
                                      }
                                      setLoading(false)
                                  setRecuperarSenha(false)
                              }).catch( error =>{
                                  
                                  Swal.fire({
                                    title: 'Printerconnect',
                                    text: 'Não foi possível enviar email',
                                    icon: 'error',
                                      confirmButtonText: 'OK'
                                    })
                                    setLoading(false)
                              })
                              }
                            })
                          }} fullWidth>Enviar link</Button> : <Button variant='contained' color ='primary' onClick={autenticar} fullWidth>{loading ? 'Autenticando...' : 'Entrar'}</Button> }
                          { loading && <LinearProgress color="secondary" />}
                <br/>
                          {!recuperarSenha ? <Button variant='contained' color ='secondary' fullWidth style ={{marginTop:"10px"}} onClick={()=>{
                            if(props.handleClose){
                              props.handleClose()
                            }
                          }}>CANCELAR</Button> : <Button variant='contained' color ='secondary' onClick={()=>setRecuperarSenha(false)} fullWidth style ={{marginTop:"10px"}}>CANCELAR</Button>  }
                <br/>
                
               <div style ={{marginTop:"10px"}}>
                          {!recuperarSenha && <span className='span-link' onClick = {()=>setRecuperarSenha(true)}>Esqueci a senha</span> }
               </div>
                </div>
             
            
   
       
        </div>
 
  );
}

export default withRouter(LoginAcesso) 