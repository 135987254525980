import React , {useState, useEffect, useRef} from 'react'
import {withRouter} from 'react-router-dom'
import './Template.css'
import ReactShadowScroll from 'react-shadow-scroll';
import BotaoSideMenu from './Compontentes/BotaoSideMenu';
import IconDash from '@material-ui/icons/PieChartOutlined'
import IconCollector from '@material-ui/icons/UsbOutlined'
import IconPrintLogger from '@material-ui/icons/VerifiedUserOutlined'
import IconOrdemServico from '@material-ui/icons/BuildOutlined'
import { Backdrop, Divider, Typography } from '@material-ui/core';
import ItemSubMenu from './Compontentes/ItemSubMenu';
import IconSuprimentos from '@material-ui/icons/ShoppingCartOutlined'
import IconUser from '@material-ui/icons/VpnKeyOutlined'
import {Breadcrumbs, LinearProgress, Badge} from '@material-ui/core'
import {Link } from 'react-router-dom'
import LogoDatacount from './logo-datacount.png'
import LogoPrinterconnect from './logoPrinterConnect.png'
import DashboardIcon from '@material-ui/icons/PieChartOutlined'
import UserIcon from '@material-ui/icons/PeopleAltOutlined'
import CollectorIcon from '@material-ui/icons/UsbOutlined'
import OrdemServicoIcon from '@material-ui/icons/BuildOutlined'
import RequisicoesIcon from '@material-ui/icons/PhoneOutlined'
import PrintLoggerIcon from '@material-ui/icons/VerifiedUserOutlined'
import IconDCA from '@material-ui/icons/AppsOutlined'
import IconColetas from '@material-ui/icons/PrintOutlined'
import IconAlertToner from '@material-ui/icons/ReportProblemOutlined'
import IconComputadores from '@material-ui/icons/ComputerOutlined'
import IconFilas from '@material-ui/icons/PrintOutlined'
import IconSetores from '@material-ui/icons/AccountBalanceOutlined'
import IconLogImpressao from '@material-ui/icons/Dehaze'
import IconRelatorios from '@material-ui/icons/AssignmentOutlined'
import IconeSair from '@material-ui/icons/ExitToApp'
import {logout  } from '../../../providers/Autenticate';
import Swal from 'sweetalert2';
import ModalTrocaSenha from './ModalTrocaSenha';

//import logoCentermaq from '../LandingPage/Componentes/logo-centermaq.png'
//import logoDigymaq from '../LandingPage/Componentes/logo-digymaq.png'
//import logoCopycenter from '../LandingPage/Componentes/logo-copycenter.png'


const Template = (props)=>{

    const [selected, setSelected] = useState(false);
    const [resolucao, setResolucao] = useState(window.screen.height > 740 ? 24 : 12)
    const [cliente, setCliente] = useState([])
  

    React.useEffect(()=>{
        if(props.location.state){

            setCliente(props.location.state.cliente);

        }else{

            if(props.cliente){

                setCliente(props.cliente);

            }

        }
    }, [])


    const handleLogoff = () =>{
        //logout();
   
       // window.location.href ='/'
      }


 

    return <div id='template-home-container'>
        <div id='side-menu-main' className='background-sideMenu-Client'>
            <div id='side-menu-logo'>
                <img src={LogoDatacount} />
            </div>
            <div id='side-menu-container'>
            <ul id="nav" >
                <li >
                    <Link to={{pathname : '/webclient/painel-cliente', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconDash /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption'>Dashboard</Typography></div>
                            </div>
                        </Link>
                </li>
                <Divider/>
                <li >
                    <ModalTrocaSenha 
                        idusuario ={cliente.idusuario} 
                        idempresa ={cliente.idempresa} 
                        idcliente ={cliente.idcliente} 
                        idacesso ={cliente.idacesso}/>
                </li>
                <Divider/>
                <li style={{cursor:'pointer'}}><a >
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconCollector style={{color:"#444"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' style={{color:"#444"}}>Collector</Typography></div>
                        </div>
                    
                    </a>
                <ul>
                    <li>
                        <Link to={{pathname : '/webclient/dcas', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconDCA /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption'>DCA`s</Typography></div>
                            </div>
                        </Link>
                        </li>
                    <Divider/>
                    <li>
                        <Link to={{pathname : '/webclient/equipamentos', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconColetas /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption'>Equipamentos</Typography></div>
                            </div>
                        </Link>
                    </li>
                    <Divider/>
                    <li>
                        <Link to={{pathname : '/webclient/alertas-suprimentos', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconAlertToner /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption'>Alertas Suprimento</Typography></div>
                            </div>
                        </Link>
                        </li>
                        <Divider/>
                        <li>
                        <Link to={{pathname : '/webclient/relatorios-collector', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconRelatorios /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption'>Relatórios</Typography></div>
                            </div>
                        </Link>
                    </li>
                   
                    
                    </ul>
                </li>
                <Divider/>

                <li style={{cursor:'pointer'}}><a >
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><IconPrintLogger style={{color:"#444"}}/></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' style={{color:"#444"}}>Print Logger</Typography></div>
                        </div>
                    
                    </a>
                <ul>
                    <li>
                        <Link to={{pathname : '/webclient/printlogger-computadores', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconComputadores /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption'>Computadores</Typography></div>
                            </div>
                        </Link>
                       </li>
                    <Divider/>
                    <li>
                        <Link to={{pathname : '/webclient/printlogger-usuarios', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><UserIcon /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Usuários</Typography></div>
                            </div>
                        </Link>
                    </li>

                    <Divider/>
                    <li>
                        <Link to={{pathname : '/webclient/printlogger-filas', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconFilas /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Filas de Impressão</Typography></div>
                            </div>
                        </Link>
                    </li>
                    <Divider/>
                    
                    <li>
                    <Link to={{pathname : '/webclient/printlogger-logs', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconLogImpressao /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Logs de Impressão</Typography></div>
                            </div>
                        </Link>
                        
                        </li>
                        <Divider/>
                    <li>
                    <Link to={{pathname : '/webclient/printlogger-relatorios', state:{
                            cliente : cliente, 
                            rowsPerPage : resolucao 
                        
                        }}}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconRelatorios /></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' >Relatórios</Typography></div>
                            </div>
                        </Link>
                       </li>
                    
                    </ul>
                </li>
                
               

                
                <Divider/>
                <li ><a >
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><Badge className='emBreve' color='secondary' badgeContent='Breve'><IconSuprimentos style={{color:"#444"}}/></Badge></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' style={{color:"#444"}}>Requisições Suprimento</Typography></div>
                        </div>                    
                    </a>
                </li>
                <Divider/>

                <li ><a>
                        <div style={{display:'flex'}}>
                            <div style ={{width : '25%', textAlign:'center'}}><Badge className='emBreve' color='secondary' badgeContent='Breve'><IconOrdemServico style={{color:"#444"}}/></Badge></div>
                            <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' style={{color:"#444"}}>Ordens de Serviço</Typography></div>
                        </div>                    
                    </a>
                </li>
                <Divider/>
                <li>
                <Link onClick={()=>{
                    props.history.push('/')
                }}>
                       
                            <div style={{display:'flex'}}>
                                <div style ={{width : '25%', textAlign:'center'}}><IconeSair style={{color:"#444"}}/></div>
                                <div style ={{width : '75%'}}><Typography variant='caption' style={{color:"#444"}}>Sair</Typography></div>
                            </div>
                        </Link>
                </li>
            </ul>
            </div>
        </div>
        <div id='container-main'>
            <div id='top-nav-menu' className='background-topMenu-Client'>
                {
                    props.breadcrumbs ? props.breadcrumbs :  <Breadcrumbs aria-label="breadcrumb">  
                    <Typography>Você esta em:</Typography>          
                        <Link color="inherit" to={'#'} >
                            Home
                        </Link>                
                    </Breadcrumbs>
                }
            </div>
            {
                props.loading && <LinearProgress variant='indeterminate' color='primary' id='progress-loading'/>
            }
            {
                props.children
            }
        </div>     
    
    </div>
}



export default withRouter(Template)


