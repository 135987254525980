import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import UsbTwoToneIcon from '@material-ui/icons/UsbTwoTone';
import Fab from '@material-ui/core/Fab';
import Conexao from '../../providers/Conexao'
import {Grid, TextField, Divider, InputAdornment} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import IconeLocalizarClienteModal from '../../components/IconeLocalizarClienteModal'
import Swal from 'sweetalert2'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [dataEmpresa, setDataEmpresa]= React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [cliente, setCliente] = React.useState({
    idcliente : '', 
    razaosocial : ''
})


  const handleClickOpen = () => {
    setOpen(true);
    setCliente({
        idcliente : '', 
        razaosocial : ''
    })
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    getDetalhesEmpresa()
  },[] )

  const getDetalhesEmpresa = () =>{ 
    Conexao.get('empresasController/getDetalheEmpresa?idempresa='+props.idempresa).then(response =>{
        if(response.data.status =='OK'){
            setDataEmpresa(response.data.payload);
        }else{
            setDataEmpresa([])
        }
    }).catch(error =>{
        setDataEmpresa([])
    });
  }


  const insertDca = () =>{ 
    setLoading(true)
    Conexao.post('dcaController/insertDca', {
        idusuario : props.idusuario, 
        idempresa : props.idempresa, 
        idcliente :cliente.idcliente , 
        chave : dataEmpresa.chaveparcial, 
        razaosocial : cliente.razaosocial
    }).then(response =>{
        if(response.data.status =='OK'){
          Swal.fire({
              title : 'Datacount', 
              text : 'DCA inserido com sucesso !', 
              showConfirmButton : false, 
              showCancelButton : false,
              icon : 'success', 
              timer : 1500
          })
          props.getData()
          setLoading(false)
        }else{
            Swal.fire({
                title : 'Datacount', 
                text : 'Não foi possível inserir DCA', 
                showConfirmButton : false, 
                showCancelButton : false,
                icon : 'warning', 
                timer : 1500
            })
        }
        setLoading(false)
        setOpen(false)
    }).catch(error =>{
        Swal.fire({
            title : 'Datacount', 
            text : 'Não foi possível inserir DCA', 
            showConfirmButton : false, 
            showCancelButton : false,
            icon : 'warning', 
            timer : 1500
        })
        setLoading(false)
        setOpen(false)
    });
  }
  return (
    <div>
     
      <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
            <UsbTwoToneIcon />
        </Fab>
      <Dialog 
        maxWidth='md'
        fullWidth
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={open} hideBackdrop 
        disableBackdropClick 
        disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Novo DCA (Coletor Automático de Dados)
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing = {2}>
              <Grid item xs = {3}>
                  <TextField
                    label ='CNPJ Revenda'
                    variant = 'outlined'
                    fullWidth
                    value = {dataEmpresa.cnpjempresa}
                    InputLabelProps ={{shrink : true}}/>
              </Grid>
              <Grid item xs = {9}>
                  <TextField
                    label ='Razão Social Revenda'
                    variant = 'outlined'
                    fullWidth
                    value = {dataEmpresa.razaosocial}
                    InputLabelProps ={{shrink : true}}/>
              </Grid>
          </Grid>
            <br/>
            
            <br/>
          <Grid container spacing = {2}>
              <Grid item xs = {2}>
                  <TextField
                    label ='Código Cliente'
                    variant = 'outlined'
                    fullWidth
                    value = {dataEmpresa.cnpjempresa}
                    InputLabelProps ={{shrink : true}}/>
              </Grid>
              <Grid item xs = {7}>
                  <TextField
                    label ='Razão Social Cliente '
                    variant = 'outlined'
                    fullWidth
                    value = {dataEmpresa.razaosocial}
                    InputLabelProps ={{shrink : true}}/>
              </Grid>

              <Grid item xs = {3}>
                  <TextField
                    label ='Chave parcial'
                    variant = 'outlined'
                    fullWidth
                    value = {dataEmpresa.chaveparcial}
                    InputLabelProps ={{shrink : true}}/>
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing = {2}>
              <Grid item xs = {3}>
                  <TextField
                    label ='Código Cliente'
                    variant = 'outlined'
                    fullWidth
                    value = {cliente.idcliente}
                    InputLabelProps ={{shrink : true}}
                    InputProps ={{
                        startAdornment : (
                            <InputAdornment position ='start'>                              
                                <IconeLocalizarClienteModal chaveCollector='T' idusuario={props.idusuario} idempresa={props.idempresa} selectCliente = {(cliente)=>setCliente({
                                    idcliente : cliente.idcliente, 
                                    razaosocial : cliente.razaosocial
                                })}
                                
                                />
                            </InputAdornment>
                        )
                    }} 
                   
                    />
              </Grid>
              <Grid item xs = {9}>
                  <TextField
                    label ='Razão Social - Cliente de Intalação'
                    variant = 'outlined'
                    fullWidth
                    value = {cliente.razaosocial}
                    InputLabelProps ={{shrink : true}}/>
              </Grid>
          </Grid>
            <br/>
        </DialogContent>
        <DialogActions>
          <Button color ='secondary' disabled={loading} onClick={handleClose} variant='contained'>Cancelar</Button>
          <Button autoFocus disabled={loading} onClick={()=>{
              Swal.fire({
                  title : 'Datacount', 
                  text :'Deseja cadastrar uma nova chave DCA ?', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar',
                  icon : 'question'
              }).then(response=>{
                  if(response.isConfirmed){
                    insertDca();
                  }
              })
          }} color="primary"  variant='contained'>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
