import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Place';
import Fab from '@material-ui/core/Fab';
import MapView from './Mapas/MapView';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalMapa = (props) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
      <Tooltip title='Ver Posicao'>
      <Typography variant='caption' style ={{fontWeight:'bold', color:"#00f", cursor : 'pointer'}} onClick={handleClickOpen}>IP Remoto: {props.ipremoto}</Typography>
        
      </Tooltip>
      <Dialog 
        
        hideBackdrop
        fullWidth
        maxWidth='lg'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
  
        <DialogContent dividers style ={{height :'80vh', padding:'0pximportant'}} >
         <MapView disableSearch = {true} latitude ={props.latitude} longitude ={props.longitude}/>

 

        </DialogContent>
        
      </Dialog>
    </div>
  );
}


export default withRouter(ModalMapa)