import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Block';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalDcas from './ModalDcas'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalNovaLicenca from './ModalNovaLicenca'
import ModalHabilitaMonitorToner from './Components/ModalHabilitarMonitoramentoToner'
import ModalHabilitarMonitoramentoEventos from './Components/ModalHabilitarMonitoramentoEventos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PrintIcon from '@material-ui/icons/Print';
import {Badge} from '@material-ui/core'
import AtivarIcon from '@material-ui/icons/Done'
import Iconfilter from '@material-ui/icons/FilterList'
import LoadingIcon from '@material-ui/icons/Cached';
import Autocomplete from '@material-ui/lab/Autocomplete'
import ContainerOrderBy from '../../components/ContainerOrderBy';

const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [licencasCollector, setLicencasCollector] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [selecionado, setSelecionado] = useState([])

    const [orderBy, setOrderBy] = useState('razaosocial'); // Estado para a coluna de ordenação
    const [orderDirection, setOrderDirection] = useState('asc'); 

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [mostrarInativos, setMostrarInativos] = useState(false); // Variável para mostrar ou não inativos
   
    const {usuario} = props.location.state


    useEffect(() => {
        fetchData();
       
    }, []);

    useEffect(() => {
        filterAndPaginateData();
    }, [data, currentPage, rowsPerPage, searchQuery, mostrarInativos, orderBy, orderDirection]); // Adicionando mostrarInativos como dependência

    const fetchData = async () => {
       
        setLoading(true);
        try {
            const response = await Conexao.post('dcaController/getLicencasCollectorFullData', {
                idusuario : props.match.params.idempresa, 
                idempresa : props.match.params.chaveEmpresa,    
            });

            if (response.status === 200 && response.data.status === 'OK') {
                setData(response.data.payload);
                setTotalRecords(response.data.payload.length);
            } else {
                setData([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const filterAndPaginateData = () => {
        
        let filtered = data;

        // Inclui registros inativos se mostrarInativos for verdadeiro
        if (!mostrarInativos) {
            filtered = filtered.filter(item => item.ativo === 't');
        }

        // Aplica filtro pela razão social
        filtered = filtered.filter(item =>
            item.razaosocial.toLowerCase().includes(searchQuery.toLowerCase())
        );


        filtered.sort((a, b) => {
            const valueA = a[orderBy] || '';  // Fallback para ''
            const valueB = b[orderBy] || '';  // Fallback para ''
        
            // Checa se algum dos valores é vazio
            if (!valueA && valueB) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            if (valueA && !valueB) {
                return orderDirection === 'asc' ? -1 : 1;
            }
        
            // Se ambos os valores são vazios ou ambos não são, compara normalmente
            if (orderDirection === 'asc') {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });


        // Define totalRecords com base na quantidade de registros filtrados
        setTotalRecords(filtered.length);

        let startIndex = currentPage * rowsPerPage;
        if (startIndex < 0) {
            startIndex = 0;
        }
        const endIndex = Math.min(startIndex + rowsPerPage, filtered.length);

        setFilteredData(filtered.slice(startIndex, endIndex));
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(0);
    };

    const handleMostrarInativosChange = (event) => {
        console.log(event.target.value)
        if(event.target.value === 'Sim'){
            setMostrarInativos(true);
        }else{
            setMostrarInativos(false);
        }
       
        setCurrentPage(0); // Redefine a página atual ao alterar mostrarInativos
    };



  

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])

    


  
const handleOrderChange = (name, value) => {
            console.log("order by "+name+' direcao '+value)
        setOrderBy(name);
        setOrderDirection(value);
        setCurrentPage(0);

    };

    

   

    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de Licenças de Coleta
            </Link>                
        </Breadcrumbs>
    }>

        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px'}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>
            
                        <Grid item xs = {7}></Grid>
                        <Grid item xs = {5}>
                        <TablePagination
                                component="div"
                                count={totalRecords}
                                page={currentPage}                
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage='Linhas por página'
                                />
                        </Grid>
                    </Grid>
        </div>
      
    
        <Grid container spacing ={1} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'30px'}}>
        <Grid item xs ={1}>
        <TextField
                select
                SelectProps={{native:true}}
                label ='Exibir Inativos'
                size='small'
                variant='outlined'
                fullWidth
                InputLabelProps={{shrink:true}}
                onChange={handleMostrarInativosChange}>
                <option value='Não'>Não</option>
                <option value='Sim'>Sim</option>
             </TextField>
        </Grid>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><SearchIcon/></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                onFocus={()=>{setCurrentPage(0)}}
                onChange={handleSearch}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={fetchData}><LoadingIcon className={loading ? 'loading-spinner icone-success' : 'icone-success'}/></Button>
        </Grid>
        
        
        <Grid item xs ={ 2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title ='Click aqui para habilitar o monitoramento deste equipamento'>
            <Button 
                variant = 'outlined' 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={!selecionado.idcliente || (selecionado && (selecionado.idcliente && selecionado.ativo =='t')) || (usuario.collectoredicao == 'f')}  onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja HABILITAR esta Licença de Coleta?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            setSelecionado([])
                            Conexao.post('dcaController/ativarLicencaCollector',{
                                idempresa : selecionado.idempresa,
                                idcliente : selecionado.idcliente, 
                                idlicenca : selecionado.idlicencacollector
                            }).then(response=>{
                                if(response.data.status.trim() == 'OK'){
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Registro atualizado com sucesso', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'success', 
                                        timer : 1500  
                                       });
                                       fetchData();
                                }else{
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Não foi possível atualizar', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'danger', 
                                        timer : 1500  
                                       });
                                }
                               
                            }).catch(error=>{
                                Swal.fire({
                                    title :'Datacount', 
                                    text : 'Registro atualizado com sucesso', 
                                    showConfirmButton : false, 
                                    showCancelButton : false,  
                                    icon :'error', 
                                    timer : 1500  
                                   });
                            })
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Habilitar 
            </Button>
            </Tooltip>
        </Grid>
        <Grid item xs ={ 2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title ='Click aqui para remover este equipamento definitivamente'>
            <Button 
                variant = 'outlined' 
                disabled={!selecionado.idcliente || ((selecionado && selecionado.idcliente && selecionado.ativo =='f')) || (usuario.collectoredicao == 'f')} 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja DESABILITAR esta Licença de Coleta?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            setSelecionado([])
                            Conexao.post('dcaController/desativarLicencaCollector',{
                                idempresa : selecionado.idempresa,
                                idcliente : selecionado.idcliente, 
                                idlicenca : selecionado.idlicencacollector
                            }).then(response=>{
                                if(response.data.status.trim() == 'OK'){
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Registro atualizado com sucesso', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'success', 
                                        timer : 1500  
                                       });
                                       fetchData();
                                       
                                }else{
                                    Swal.fire({
                                        title :'Datacount', 
                                        text : 'Não foi possível atualizar', 
                                        showConfirmButton : false, 
                                        showCancelButton : false,  
                                        icon :'warning', 
                                        timer : 1500  
                                       });
                                }
                               
                            }).catch(error=>{
                                Swal.fire({
                                    title :'Datacount', 
                                    text : 'Não foi possível atualizar', 
                                    showConfirmButton : false, 
                                    showCancelButton : false,  
                                    icon :'error', 
                                    timer : 1500  
                                   });
                            })
                        }
                    })
                    
                   
                }}>
                    <LockIcon /> Desabilitar
            </Button>
            </Tooltip>
        </Grid>
        

        <Grid item xs={3}>
        {usuario.idusuariorevenda == null || usuario.collectormonitorartoner == 't' ?
            <ModalHabilitaMonitorToner disabled={!selecionado.idcliente} getData={()=>fetchData()} licenca={selecionado}/>
            : null}
        </Grid>
    </Grid>
        <br/>
        
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-small table-sm' style ={{fontSize:'12px'}}>
            <TableHead>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>ID</TableCell>
                <TableCell align='left' style = {{width:"45%",fontSize:'12px'}}>
                <ContainerOrderBy 
                     title='Razão Social' 
                     help ='Ordena a lista pela Razão Social do cliente'
                     handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                     direcao = {orderDirection}
                     ativo = {orderBy}
                     campo ='razaosocial'
                     font = {10}
                    />
                </TableCell>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}><span style = {{fontSize :'10px'}}>CRIAÇÃO</span></TableCell>  
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}><span style = {{fontSize :'10px'}}>COLETORES DCA</span></TableCell>                
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}><span style = {{fontSize :'10px'}}>EQUIPAMENTOS</span></TableCell>
                <TableCell align='center' style = {{width:"8%",fontSize:'12px'}}><span style = {{fontSize :'10px'}}>TONER</span></TableCell>
                {/*<TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Eventos</TableCell>*/}
                <TableCell align='center' style = {{width:"8%",fontSize:'12px'}}>STATUS</TableCell>
            </TableHead>

            <TableBody>
                
                {
                    filteredData && filteredData.map(function(licenca, i){
                        return <TableRow key ={i} onClick={()=>{
                    
                            if(selecionado && selecionado.idcliente == licenca.idcliente  && selecionado.chave == licenca.chave){
                                setSelecionado([])
                            }else{
                                setSelecionado(licenca)
                               
                            }
                        }} style ={{
                            backgroundColor : selecionado && selecionado.idcliente == licenca.idcliente  && selecionado.chave == licenca.chave ? "#E3F2FD" : '',
                            opacity : selecionado.chave && selecionado.chave != licenca.chave ? 0.5 : 1 
                        }}>
                            <TableCell align='center' style ={{fontSize:'12px'}}>{ i + 1}</TableCell>
                
                        <TableCell align='left' style ={{fontSize:'12px'}} >{licenca.razaosocial}</TableCell>
                        <TableCell align='center' style ={{fontSize:'12px'}}>{licenca.datacriacao}</TableCell>    
                        <TableCell align='center' style ={{fontSize:'12px'}}>
                         
                            <ModalDcas usuario={usuario} chave={licenca.chave} Conexao={Conexao} idusuario={licenca.idusuario} idempresa={licenca.idempresa} razaosocial={licenca.razaosocial} idcliente={licenca.idcliente} />
                           
                            </TableCell>                  
                            <TableCell align='center' style ={{fontSize:'12px'}}>
                                <Tooltip title='Clique para ver os equipamentos vinculados a este cliente'>
                                <Badge color='secondary' badgeContent={licenca.equipamentos}><PrintIcon style={{cursor:'pointer'}} color='primary' /></Badge>
                                </Tooltip>
                            </TableCell>    
                        <TableCell align='center' style ={{fontSize:'12px'}} >
                                {
                                    licenca.monitorartoner =='t' ? <Typography style ={{fontSize:"12px"}}>HABILITADO</Typography>:<Typography style ={{fontSize:"12px"}}>DESABILITADO</Typography>
                                }
                    
                        </TableCell>
                       {/*} <TableCell align='center' style ={{fontSize:'12px'}} >
                                <ModalHabilitarMonitoramentoEventos getData={()=>getLicencasCollector()} licenca={licenca}/>
                    {licenca.monitorareventos == 't' ? <span style ={{fontSize:"9px"}}>ATIVO</span> : <span style ={{fontSize:"9px"}}>INATIVO</span> }
                            </TableCell>*/}
                        <TableCell align='center' style ={{fontSize:'12px'}} >
                        {
                                    licenca.ativo =='t' ? <Tooltip title='Cliente ativo'><span style={{paddingLeft:'5px', paddingRight : '5px', borderRadius :'3px', fontSize :'10px', borderColor : 'green', color :'green', border :'solid 1px'}}>ATIVO</span></Tooltip> : 
                                    <Tooltip title='Cliente inativo'><span style={{paddingLeft:'5px', paddingRight : '5px', borderRadius :'3px', fontSize :'10px', borderColor : 'red', color :'red', border :'solid 1px'}}>INATIVO</span></Tooltip>
                                }
                           </TableCell>
                    </TableRow>
                    })
                }
                {filteredData.length == 0 && <TableRow>
                    <TableCell colSpan={11}>
                    <Typography variant = 'caption' color='primary'>* Não existe(m) registro(s)</Typography>
                    </TableCell>
                    
                    
                    </TableRow>}
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>

    <div className='div-paginator-button'  >
            <div style={{display :'flex', alignContent:'center',  alignItems:'center', backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Total de registros:</strong> {totalRecords}</Typography>
            </div>
            <div style ={{ display:'flex', alignItems:'center'}}>
                <TablePagination
                        component="div"
                        count={totalRecords}
                        page={currentPage}                
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage='Linhas por página'
                        rowsPerPageOptions={[]}
                        />
            </div>
            <div style={{ backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Order by:</strong> {orderBy}</Typography><br/>
            <Typography variant='caption'><strong>Direction:</strong> {orderDirection}</Typography>
            </div>
        </div>


    <div className='fabIncluir' style={{marginBottom :'200px!important'}}>
        {usuario.idusuariorevenda == null || usuario.collectorinclusao =='t' ?
        <ModalNovaLicenca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>fetchData()}/>
        :  null }
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)