import React, {useState, useRef, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import Template from '../Template/Template'
import {Breadcrumbs, Typography, Container, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, 
TextField, InputAdornment, Tooltip} from '@material-ui/core'
import {Link } from 'react-router-dom'
import Conexao from '../../../providers/Conexao'
import Swal  from 'sweetalert2'
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'react-bootstrap'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import IconGo from '@material-ui/icons/KeyboardArrowRight'
import CaretIcon from '@material-ui/icons/ArrowDropUp'
import FilterIconUp from '@material-ui/icons/ArrowDropUp'
import FilterIconDown from '@material-ui/icons/ArrowDropDown'
import ModalDetalhesLeitura from '../../../pages/ContadoresAoVivo/DetalhesColeta'
import ModalFullDetalhes from '../../../components/ModalFull2'
import DisplayToner from '../../../components/DisplayToner'
import DisplayLinearToner from '../../../components/DisplayLinearToner'
import WebClientesModalNovoEquipamento from './WebClientesModalNovoEquipamento'



import { paginateArray, 
    calculateTotalPages, 
    nextPage, 
    previousPage, 
    ordenarDataColeta, 
    ordenarSerial, 
    ordenarMarca, 
    ordenarModelo, 
    ordenarMedidor, 
    ordenarNivelCiano, 
    ordenarNivelMagenta,
    ordenarNivelAmarelo, 
    ordenarNivelPreto } from '../../../providers/paginationUtils.js';








const WebClientesEquipamentos = (props) =>{
    const { idcliente, idusuario, idempresa, razaosocial} = props.location.state.cliente;
    const [coletas, setColetas] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(props.location.state.rowsPerPage)
    const [page, setPage] = useState(0)
    const [loading, setLoading]= useState(false)
    const [registros, setRegistros] = useState([])
    const [showFilters, setShowFilters] = useState(false)
    const [coleta, setColeta] = useState([])
    const [tipoOrdenacao, setTipoOrdenacao] = useState('ordenarDataColeta')
    const [ordenacao, setOrdenacao] = useState('asc')
    
    
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setColetas(paginateArray(registros, rowsPerPage, newPage)) 
    };

  
    useEffect(()=>{
        getData()
    },[])
    
    useEffect(()=>{
        ordenarDados()
    },[tipoOrdenacao])

    const ordenarDados = () =>{
        setLoading(true)
        
        switch(tipoOrdenacao){
            
            case 'ordenarDataColeta': 
                setColetas(paginateArray(ordenarDataColeta(registros,ordenacao ), rowsPerPage, page))          
            break;

            case 'ordenarSerial':             
                setColetas(paginateArray(ordenarSerial(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarMarca': 
                setColetas(paginateArray(ordenarMarca(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarModelo': 
                setColetas(paginateArray(ordenarModelo(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarMedidor':             
                setColetas(paginateArray(ordenarMedidor(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarNivelCiano':             
                setColetas(paginateArray(ordenarNivelCiano(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarNivelMagenta':             
                setColetas(paginateArray(ordenarNivelMagenta(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarNivelAmarelo':             
                setColetas(paginateArray(ordenarNivelAmarelo(registros,ordenacao ), rowsPerPage, page))   
            break;

            case 'ordenarNivelPreto':             
                setColetas(paginateArray(ordenarNivelPreto(registros,ordenacao ), rowsPerPage, page))   
            break;
        }        
        setLoading(false)
    }

    const rendeTipoOrdenacao = (value = null, tipoPesquisa = 'ordenacao') =>{
      
        switch(tipoOrdenacao){
            
            case 'ordenarDataColeta': 
                if(tipoPesquisa == 'ordenacao'){
                    return 'Data de coleta' 
                }else{
                    setColetas(registros.filter(keyword => keyword.datacoleta.toLowerCase().includes(value.toLowerCase())));
                }
                         
            break;

            case 'ordenarSerial':  
                if(tipoPesquisa == 'ordenacao'){
                    return 'Número de Série'
                }else{
                    setColetas(registros.filter(keyword => keyword.numeroserie.toLowerCase().includes(value.toLowerCase())));
                }           
                      
            break;

            case 'ordenarMarca': 
                if(tipoPesquisa == 'ordenacao'){
                    return 'Marca '
                }else{
                    setColetas(registros.filter(keyword => keyword.marca.toLowerCase().includes(value.toLowerCase())));
                }
                     
            break;

            case 'ordenarModelo': 
                if(tipoPesquisa == 'ordenacao'){
                    return 'Modelo'   
                }else{
                    setColetas(registros.filter(keyword => keyword.modelo.toLowerCase().includes(value.toLowerCase())));
                }
                  
            break;

            case 'ordenarMedidor': 
                if(tipoPesquisa == 'ordenacao'){
                    return 'Medidor' 
                }else{
                    setColetas(registros.filter(keyword => keyword.mediro.toLowerCase().includes(value.toLowerCase())));
                }            
                       
            break;

            case 'ordenarNivelCiano':  
                if(tipoPesquisa == 'ordenacao'){
                    return 'Nível Toner Ciano'
                }else{
                   // setColetas(registros.filter(keyword => keyword.nivelciano != null &&   keyword.nivelciano.toLowerCase().includes(value.toLowerCase())));
                }           
                      
            break;

            case 'ordenarNivelMagenta':  
                if(tipoPesquisa == 'ordenacao'){
                    return 'Nível Toner Magenta'
                }else{
                   // setColetas(registros.filter(keyword => keyword.nivelmagenta != null &&  keyword.nivelmagenta.toLowerCase().includes(value.toLowerCase())));
                }           
                        
            break;

            case 'ordenarNivelAmarelo':
                if(tipoPesquisa == 'ordenacao'){
                    return 'Nível Toner Amarelo'         
                }else{
                    //setColetas(registros.filter(keyword => keyword.nivelamarelo != null &&   keyword.nivelamarelo.toLowerCase().includes(value.toLowerCase())));
                }             
                
            break;

            case 'ordenarNivelPreto':    
                if(tipoPesquisa == 'ordenacao'){
                    return 'Nível Toner Preto'  
                }else{
                    //setColetas(registros.filter(keyword => keyword.nivelpreto != null &&  keyword.nivelpreto.toLowerCase().includes(value.toLowerCase())));
                }         
                      
            break;
        }    
    }
    const renderTipo = () =>{
        return ordenacao == 'asc' ? " crescente" : ' decrescente'
    }
 

    useEffect(()=>{
        setColetas(paginateArray(registros, rowsPerPage, page))
     
    },[registros])

    useEffect(()=>{
        ordenarDados()
     
    },[ordenacao])



    const getData = () =>{
        setLoading(true)
        Conexao.post('webClientController/getContadores', {
            idempresa : idempresa, 
            idusuario :idusuario, 
            idcliente : idcliente,
           
        }).then(response=>{
            if(response.data.status == 'OK'){
                const temp = ordenarDataColeta(response.data.payload, 'desc')
                setRegistros(temp)
               
    
               
            }else{
                setRegistros([])
               
            }
            setLoading(false)
        }).catch(error =>{
            setRegistros([])
            setLoading(false)
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }


    return <Template loading={loading} cliente={props.location.state.cliente} breadcrumbs ={
        <Grid container spacing = {1}>
            <Grid item xs = {7}>
            <Breadcrumbs aria-label="breadcrumb">  
            <Typography>Você esta em:</Typography>          
                <Link color="inherit" to={'/webclient/home'} >
                    Home
                </Link>   
                <Typography>Equipamentos</Typography>       
            </Breadcrumbs>
            </Grid>
            <Grid item xs = {5}>
                <div style ={{paddingTop : '90px'}}>
                <TablePagination
                                component="div"
                                count={registros.length}
                                page={page}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}                               
                                labelRowsPerPage='Registros por página'
                                rowsPerPageOptions={props.location.state.rowsPerPage}
                                />
                </div>
            </Grid>
        </Grid>
        
    }>
        <div className='div-titulo-main' style={{paddingLeft :'25px'}}>
            <Typography variant='h5'>Equipamentos</Typography>
        </div>
        <div style ={{paddingLeft: '30px', paddingRight: '30px', paddingTop : '30px'}}>
           <div style ={{display:'flex'}}>
                <div>
                    <Button className={showFilters ? 'button-default-selected' : 'button-default'} color='primary' onClick={()=>setShowFilters(!showFilters)}>
                        <Typography>{showFilters ? "Filtro" : "Filtro off"}</Typography>
                    </Button>
                    {showFilters && <div id='div-container-filter'>
                        <CaretIcon className='icone-caret' />  
                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={2}>
                                <Button className='button-default' onClick={()=>setShowFilters(false)}><Typography variant='caption' style={{textAlign:'center'}} >Limpar</Typography></Button>
                            </Grid>
                            <Grid item xs ={7} style={{textAlign:'center'}}>
                                <Typography variant='caption' style={{textAlign:'center'}}>Filtros</Typography>
                            </Grid>
                            <Grid item xs ={2}>
                                <Button className='button-default'><Typography variant='caption' style={{textAlign:'center'}}>Filtrar</Typography></Button> 
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={3}>
                                <Typography variant='caption'>Marca</Typography>
                            </Grid>
                            <Grid item xs ={9}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    size='small'/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={3}>
                                <Typography variant='caption'>Modelo</Typography>
                            </Grid>
                            <Grid item xs ={9}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    size='small'/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={3}>
                                <Typography variant='caption'>Data Inicio</Typography>
                            </Grid>
                            <Grid item xs ={9}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    type='date'
                                    size='small'/>
                            </Grid>
                        </Grid>  
                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={3}>
                                <Typography variant='caption'>Data Fim</Typography>
                            </Grid>
                            <Grid item xs ={9}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    type='date'
                                    size='small'/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={3}>
                                <Typography variant='caption'>Ordenar por</Typography>
                            </Grid>
                            <Grid item xs ={9}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    SelectProps={{native:true}}
                                    select
                                    onChange={(e)=>{
                                        setTipoOrdenacao(e.target.value)
                                    }}
                                    size='small'>
                                        <option value={tipoOrdenacao}>{rendeTipoOrdenacao()}</option>
                                        <option value={'ordenarSerial'}>Ordenar Número Série</option>                                        
                                        <option value={'ordenarMarca'}>Ordenar Marca</option>
                                        <option value={'ordenarModelo'}>Ordenar Modelo</option>
                                        <option value={'ordenarDataColeta'}>Ordenar Data Coleta</option>
                                        <option value={'ordenarMedidor'}>Ordenar Medidor</option>
                                        <option value={'ordenarNivelCiano'}>Ordenar Nível Ciano</option>
                                        <option value={'ordenarNivelMagenta'}>Ordenar Nível Magenta</option>
                                        <option value={'ordenarNivelAmarelo'}>Ordenar Nível Amarelo</option>
                                        <option value={'ordenarNivelPreto'}>Ordenar Nível Preto</option>
                                        
                                </TextField>
                            </Grid>
                        </Grid>  

                        <Grid container spacing={1} style ={{padding:'5px'}}>
                            <Grid item xs ={3}>
                                <Typography variant='caption'>Ordenação</Typography>
                            </Grid>
                            <Grid item xs ={9}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    SelectProps={{native:true}}
                                    select
                                    onChange={(e)=>{
                                        setOrdenacao(e.target.value)
                                    }}
                                    size='small'>
                                        <option value = {ordenacao}>{ordenacao =='asc' ? 'crescente' :'decrescente'}</option>
                                        {
                                            ordenacao =='asc' ? <React.Fragment>
                                                <option value ='desc'>Decrescente</option>
                                                <option value ='asc'>Crescente</option>
                                            </React.Fragment> : <React.Fragment>
                                                <option value ='asc'>Crescente</option>
                                                <option value ='desc'>Decrescente</option>
                                            </React.Fragment>

                                        }
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>}
                </div>
                <div style={{marginLeft:'10px', width:'400px'}}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size='small'
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                
                                rendeTipoOrdenacao(e.target.value, 'pesquisa')
                                
                                
                            }
                        }}
                        InputProps={{
                            startAdornment:(<InputAdornment position="start"><SearchIcon style={{color:'#444'}}/></InputAdornment>), 
                            endAdornment:(<InputAdornment position="end">
                                <Tooltip title ='Buscar dados atualizados...'>
                                    <Button variant='contained' color='secondary' onClick={()=>{
                                        getData()
                                    }}><IconGo style={{color:'#000'}}/></Button>
                                </Tooltip>
                            </InputAdornment>), 
                        }}/>
                </div>
                <div style={{marginLeft:'10px', }}>
                    <Typography variant='caption'>Ordenar por: {rendeTipoOrdenacao()+ renderTipo()}</Typography>
                </div>
                <div>
                    
                </div>
           </div>
            <Table className='table-small table-sm table-dark ' variant='dark'>
                <TableHead>
                    <TableCell style ={{display:'flex'}}><Typography style={{cursor:'pointer'}} onClick={()=>{
                        if(tipoOrdenacao != 'ordenarSerial'){
                            setTipoOrdenacao('ordenarSerial')
                        }else{
                            setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                        }
                        
                    }}>Serial</Typography></TableCell>
                    <TableCell style ={{width : '15%'}}><Typography style={{cursor:'pointer',}} onClick={()=>{
                     
                        if(tipoOrdenacao != 'ordenarMarca'){
                            setTipoOrdenacao('ordenarMarca')
                        }else{
                            setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                        }
                    }}>Marca</Typography></TableCell>
                    <TableCell style ={{width : '20%'}}><Typography style={{cursor:'pointer', }} onClick={()=>{
                       
                        if(tipoOrdenacao != 'ordenarModelo'){
                            setTipoOrdenacao('ordenarModelo')
                        }else{
                            setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                        }
                    }}>Modelo</Typography></TableCell>
                    <TableCell align='center' style ={{width : '5%'}}><Typography>PAT</Typography></TableCell>
                    <TableCell align='center' style ={{width : '5%'}}><Typography  style={{cursor:'pointer',}} onClick={()=>{
                      
                        if(tipoOrdenacao != 'ordenarDataColeta'){
                            setTipoOrdenacao('ordenarDataColeta')
                        }else{
                            setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                        }
                    }}>Data</Typography></TableCell>

                    <TableCell align='center' style ={{width : '5%'}}><Typography  >Hora</Typography></TableCell>
                    <TableCell align='center' style ={{width : '10%'}}><Typography  style={{cursor:'pointer',}} onClick={()=>{
                     
                        if(tipoOrdenacao != 'ordenarMedidor'){
                            setTipoOrdenacao('ordenarMedidor')
                        }else{
                            setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                        }
                    }}>Medidor</Typography></TableCell>
                    <TableCell align='center' style ={{width : '15%'}} >
                        <div className='div-flex-center' style={{width:'100%'}}>
                            <Typography style={{width:'19%', cursor:'pointer',textAlign:'center'}} onClick={()=>{
                     
                     if(tipoOrdenacao != 'ordenarNivelCiano'){
                         setTipoOrdenacao('ordenarNivelCiano')
                     }else{
                         setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                     }
                 }}>C</Typography>
                            <Typography style={{width:'19%',cursor:'pointer', textAlign:'center'}}onClick={()=>{
                     
                     if(tipoOrdenacao != 'ordenarNivelMagenta'){
                         setTipoOrdenacao('ordenarNivelMagenta')
                     }else{
                         setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                     }
                 }}>M</Typography>
                            <Typography style={{width:'19%',cursor:'pointer', textAlign:'center'}}onClick={()=>{
                     
                     if(tipoOrdenacao != 'ordenarNivelAmarelo'){
                         setTipoOrdenacao('ordenarNivelAmarelo')
                     }else{
                         setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                     }
                 }}>Y</Typography>
                            <Typography style={{width:'19%',cursor:'pointer', textAlign:'center'}}onClick={()=>{
                     
                     if(tipoOrdenacao != 'ordenarNivelPreto'){
                         setTipoOrdenacao('ordenarNivelPreto')
                     }else{
                         setOrdenacao(ordenacao == 'asc' ? 'desc' : 'asc')
                     }
                 }}>K</Typography>
                        </div>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {
                        coletas && coletas.map(function(coleta, i){
                            return <TableRow className='row-hover' onClick={()=>{
                                if(!showFilters){
                                    setColeta(coleta)
                                }
                               
                            }}>
                            <TableCell style ={{backgroundColor : tipoOrdenacao == 'ordenarSerial' ? '#2196F3!important' :'#fff!important'}}><Typography>{coleta.numeroserie}</Typography></TableCell>
                            <TableCell ><Typography>{coleta.marca}</Typography></TableCell>
                            <TableCell ><Typography>{coleta.modelo}</Typography></TableCell>
                            <TableCell align='center' ><Typography></Typography></TableCell>
                            <TableCell align='center' ><Typography>{coleta.datacoleta ? coleta.datacoleta : ''}</Typography></TableCell>
                            <TableCell align='center' ><Typography>{coleta.horacoleta ? coleta.horacoleta.substr(0,8) : ''}</Typography></TableCell>
                            <TableCell align='center' >
                                <div><Typography>{coleta.contadortotal}</Typography></div>
                            </TableCell>
                            <TableCell align='center'  >
                                <div className='div-flex-center'>
                                    <div style ={{width:'19%'}}>
                                        <DisplayLinearToner small  value={coleta.nivelciano ? coleta.nivelciano : 0 } color='#4FC3F7'/>
                                    </div>
                                    <div style ={{width:'19%'}}>
                                        <DisplayLinearToner  small value={coleta.nivelmagenta ? coleta.nivelmagenta : 0} color='#E57373'/>
                                    </div>
                                    <div style ={{width:'19%'}}>
                                        <DisplayLinearToner small  value={coleta.nivelamarelo ? coleta.nivelamarelo : 0} color='#FFD54F'/>
                                    </div>
                                    <div style ={{width:'19%'}}>
                                        <DisplayLinearToner small value={coleta.nivelpreto ? coleta.nivelpreto : 0} color='#000'/>
                                    </div>
                                    
                                </div>
                            </TableCell>
                        </TableRow>
                        })
                    }
                   
                </TableBody>

                <TableFooter>
                        <TableRow>
                            <TableCell align='left' colSpan={7}>
                           
                            </TableCell>
                            
                        </TableRow>
                </TableFooter>
            </Table>
        </div>
        <ModalFullDetalhes 
            showModal={coleta && coleta.idcoleta} 
            marginTop
            setOpen = {()=>setColeta([])}
            
            titulo ={coleta.idcoleta && coleta.numeroserie+' - '+coleta.modelo}>
            <ModalDetalhesLeitura versaoCliente= {false} closeModal={()=>setColeta([])} state = {coleta} showModal={coleta && coleta.idcoleta} idleitura={coleta.idcoleta}/>
        </ModalFullDetalhes>

        <div className='fabIncluir'>
            {/*<WebClientesModalNovoEquipamento />*/}
        </div>
    </Template>
}

export default withRouter(WebClientesEquipamentos)