import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, Grid, TextField} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Place';
import Fab from '@material-ui/core/Fab';




const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalEdicaoData = (props) => {
    const [open, setOpen] = React.useState(false)
    const {inventory} = props
    const [patrimonio, setPatrimonio] = React.useState('')
    const [modelo, setModelo] = React.useState('')
    

    useEffect(()=>{
        if(!open){
            setPatrimonio('')
            setModelo('')
        }else{
            console.log(inventory)
            setPatrimonio(inventory.patrimonio)
            setModelo(inventory.modeloAlternativo ? inventory.modeloAlternativo : '')
        }
    }, [open])

    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
      <Tooltip title='Editar informações do equipamento (PAT, Modelo)'>
        <Typography variant='h3' style ={{fontWeight:'bold', color:"#00f", cursor : 'pointer',}} onClick={handleClickOpen}>{props.descricao}</Typography>        
      </Tooltip>
      <Dialog         
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Editar Item Inventory
        </DialogTitle>
        <DialogContent dividers style ={{ padding:'0pximportant'}} >
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant='subtitle1'>Modelo</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size ='small'
                        value={inventory.Modelo ? inventory.Modelo.Model : ''}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant='subtitle1'>Modelo Alternativo</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        placeholder='Informe o serial alternativo caso necessário'
                        size ='small'
                        value={modelo}
                        onChange={(e)=>setModelo(e.target.value)}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant='subtitle1'>Número Série</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size ='small'
                        value={inventory.Bios ? inventory.Bios.SerialNumber : '* não disponível'}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant='subtitle1'>HostName</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size ='small'
                        value={inventory.hostName ? inventory.hostName : '* não disponível'}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant='subtitle1'>PAT</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        size ='small'
                        placeholder='Informe o número de patrimônio'
                        value={patrimonio}
                        onChange={(e)=>setPatrimonio(e.target.value)}
                    />
                </Grid>
            </Grid>

 

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus  color="primary" variant='contained' onClick = {()=>{
            Swal.fire({
                title : 'Datacount Inventory', 
                text : 'Deseja gravar as alterações?', 
                icon:'question', 
                showCancelButton:true, 
                cancelButtonText : 'Não', 
                showConfirmButton : true, 
                confirmButtonText : 'SIM'
            }).then(resp=>{
                if(resp.isConfirmed){
                    props.ConexaoInventory.post('InventoryController/updateOneInterface', {
                        _id : inventory._id, 
                        modeloAlternativo : modelo, 
                        patrimonio : patrimonio
                    }).then(response=>{
                        if(response.status == 200 && response.data.status =='OK'){
                            props.setPat(patrimonio);
                            Swal.fire({
                                title : 'Datacount Inventory', 
                                text : 'Alteração gravada com sucesso!', 
                                icon:'success', 
                                showCancelButton:false, 
                                cancelButtonText : 'Não', 
                                showConfirmButton : false, 
                                confirmButtonText : 'SIM', 
                                timer : 1500
                            })  
                        }else{
                            Swal.fire({
                                title : 'Datacount Inventory', 
                                text : 'Não foi possível alterar', 
                                icon:'warning', 
                                showCancelButton:false, 
                                cancelButtonText : 'Não', 
                                showConfirmButton : false, 
                                confirmButtonText : 'SIM',
                                timer : 1500
                            })  
                        }
                        setOpen(false)
                    }).catch(error=>{
                        Swal.fire({
                            title : 'Datacount Inventory', 
                            text : 'Não foi possível alterar', 
                            icon:'error', 
                            showCancelButton:false, 
                            cancelButtonText : 'Não', 
                            showConfirmButton : false, 
                            confirmButtonText : 'SIM',
                            timer : 1500
                        })  
                    })
                    setOpen(false)
                }
            })
          }}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalEdicaoData)