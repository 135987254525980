import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, Chip, Table, TableHead, TableCell, TableBody, TableRow, Grid, TextField} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';




const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalAlteraSetores = (props) => {
    const [open, setOpen] = React.useState(false)
    const [syslocation, setSyslocation] = React.useState('')

    
    useEffect(()=>{
      setSyslocation('')
    },[props.setOpenModalSyslocation])


    
  return (
    <div>
     
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='lg'
        onClose={props.setOpenModalSyslocation} aria-labelledby="customized-dialog-title" open={props.openModalSyslocation}>
        <DialogTitle id="customized-dialog-title" onClose={props.setOpenModalSyslocation}>
         Alterar Setores (Syslocation)
        </DialogTitle>
        <DialogContent dividers style = {{height:"450px", overflow:"auto"}}>
         <div>
          

          <Table className='table table-striped table-hover table-hovered table-sm table-small'>
            <TableHead>
              <TableCell style={{width: '15%'}}>Número Série</TableCell>
              <TableCell style={{width: '35%'}}>Modelo</TableCell>
              <TableCell style={{width: '35%'}}>Cliente</TableCell>
              <TableCell style={{width: '15%'}}>Setor</TableCell>
            </TableHead>
            <TableBody>
            {
            props.selecionados && props.selecionados.map(function(selecionado, i){
              return <TableRow>
                        <TableCell>{selecionado.numeroserie}</TableCell>
                        <TableCell>{selecionado.modelo}</TableCell>
                        <TableCell>{selecionado.razaosocial}</TableCell>
                        <TableCell> {selecionado.setor ? selecionado.setor : selecionado.syslocation}</TableCell>
                    </TableRow>
            })
          }
            </TableBody>
          </Table>
         
         </div>
        </DialogContent>
        <DialogActions>
        <Grid container spacing={2}>
            <Grid item xs={8} style={{marginTop:'10px'}}>
              <TextField
                variant='outlined'
                fullWidth
                value={syslocation}
                onChange={(e)=>setSyslocation(e.target.value)}
                InputLabelProps={{shrink:true}}
                label='Syslocation(Setor)'
                placeholder='Informe o Setor (Syslocation) ou deixe em branco para limpar'
              />
        
            </Grid>
            <Grid item xs={2}>
                <Button autoFocus variant='contained' color='primary' style={{height:'100%'}} fullWidth onClick={()=>props.setSyslocationLote(syslocation)} >
                  Gravar
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button autoFocus variant='contained' color='secondary' style={{height:'100%'}} fullWidth onClick={props.setOpenModalSyslocation} >
                  Cancelar
                </Button>
            </Grid>
          </Grid>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}




export default withRouter(ModalAlteraSetores)