import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, Container,   Button,Divider, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import PlaceIcon from '@material-ui/icons/Place';
import TextFieldCNPJ from '../../components/TextFieldCNPJ'
import TextFieldTelefone from '../../components/TextFieldTelefone'
import TextFieldCelular from '../../components/TextFieldCelular'
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import UsbIcon from '@material-ui/icons/Usb';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PrintIcon from '@material-ui/icons/Print';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import ModalFull from '../../components/ModalFull'
import FormNovoLocalClientes from './FormNovoLocalClientes'
import TextFieldCep from '../../components/TextFieldCep'
import CadastroLocaisCliente from './CadastroLocaisClientes'
import ModalEdicaoLocalCliente from '../../components/ModalFull'
import CreateIcon from '@material-ui/icons/Create';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ModalNovoDepartamento from './Componentes/ModalNovoDepartamento'
import TextFieldEmail from '../../components/TextFieldEmail'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalListaLocais from './ModalListaLocaisCliente'

import IconeNovoLocal from '@material-ui/icons/AddLocation'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }


  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));

const FormNovoCliente = (props) =>{
    const [idCliente, setIdCliente]= useState('')
    const [cnpj, setCnpj] = useState('')
    const [inscEstadual, setInscEstadual] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [cep, setCep] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade,setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [telefoneComercial, setTelefoneComercial] = useState('')
    const [telefoneCelular, setTelefoneCelular]  = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [locaisCliente, setLocaisCliente] = React.useState([]);
    const [showModal, setShowModal] =useState(false)
    const[codigoClienteERP, setCodigoClienteERP]= useState('')
    const [localClienteEdicao, setLocalClienteEdicao] = useState([])
    const [departamentoEdicao, setDepartamentoEdicao] = useState([])
    const [departamentos, setDepartamentos]= useState([])
    const [loading, setLoading] = React.useState(false);



    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(()=>{
        
        if(props.edicaoClientes && props.edicaoClientes.idcliente != ''){
            setIdCliente(props.edicaoClientes.idcliente)
            setCnpj(props.edicaoClientes.cpf_cnpj)
            setInscEstadual(props.edicaoClientes.rg_insc)
            setRazaoSocial(props.edicaoClientes.razaosocial)
            setNomeFantasia(props.edicaoClientes.nomefantasia)
            setCep(props.edicaoClientes.cep)
            setEndereco(props.edicaoClientes.logradouro)
            setNumero(props.edicaoClientes.numero)
            setComplemento(props.edicaoClientes.complemento)
            setBairro(props.edicaoClientes.bairro)
            setCidade(props.edicaoClientes.cidade)
            setEstado(props.edicaoClientes.estado)
            setCodigoClienteERP(props.edicaoClientes.idclienteinterno);
         
            setTelefoneComercial(props.edicaoClientes.telefonecomercial ? props.edicaoClientes.telefonecomercial : '')
            
     
            setTelefoneCelular(props.edicaoClientes.telefonecelular ? props.edicaoClientes.telefonecelular : '')
            
        
            
            setEmail(props.edicaoClientes.email ? props.edicaoClientes.email : '')
            getLocaisCliente();
            getDepartamentos();
            
        }
        
    }, [props.edicaoClientes])


    useEffect(()=>{
        
        if(!props.edicaoClientes){
           
            limparControlesCliente()
        }
        
    }, [props.edicaoClientes])

    const limparControlesCliente = () =>{
        setIdCliente('')
        setCnpj('')
        setInscEstadual('')
        setRazaoSocial('')
        setNomeFantasia('')
        setCep('')
        setEndereco('')
        setNumero('')
        setComplemento('')
        setBairro('')
        setCidade('')
        setEstado('')
        setCodigoClienteERP('');         
        setTelefoneComercial( '')            
        setTelefoneCelular( '')
        setEmail( '')
    }
    const cadastrarCliente = () =>{
        setError(false)
   
        if((cnpj =='' || razaoSocial ==''  || cidade == '' ||  estado == '') || (!cnpj || !razaoSocial || !cidade || !estado)) {
            
            setError(true)
            Swal.fire({
                text:'Você precisa informar todos os campos obrigatórios !', 
                icon :'warning', 
                timer : 1500, 
                showCancelButton:false, 
                showConfirmButton:false
            })
           return false;
        }else{

        
            
           
        
        Swal.fire({
            icon : 'info',
            text :'Deseja Gravar as alterações ?',                  
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText : 'Cancelar', 
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
            if (result.isConfirmed) {
                if(props.edicaoClientes && props.edicaoClientes.idcliente ){
                    Conexao.post('clientesController/updateCliente',{
                        idcliente : idCliente, 
                        idusuario : props.idempresa, 
                        idempresa : props.chaveEmpresa, 
                        cnpj :cnpj, 
                        inscEstadual :inscEstadual, 
                        razaoSocial :razaoSocial, 
                        nomeFantasia : nomeFantasia, 
                        endereco : endereco, 
                        numero : numero, 
                        cep :cep, 
                        complemento : complemento, 
                        bairro :bairro, 
                        cidade : cidade, 
                        estado :estado, 
                        telefoneCelular : telefoneCelular, 
                        telefoneComercial :telefoneComercial, 
                        email : email, 
                        codigoClienteERP : codigoClienteERP
                    }).then( response =>{
                        //alert(response.status+' '+response.data)
                        if(response.status == 200){
                            if(response.data.status.trim() == 'OK'){
                                Swal.fire({
                                    text:'Cliente adicionado com sucesso !', 
                                    icon :'success', 
                                    timer : 1500, 
                                    showCancelButton:false, 
                                    showConfirmButton:false
                                })
                                props.updateClientes()
                                props.closeModal();
                            }else{
                                Swal.fire({
                                    text:'Não foi possivel adicionar !', 
                                    icon :'warning', 
                                    timer : 1500, 
                                    showCancelButton:false, 
                                    showConfirmButton:false
                                })
                                props.updateClientes()
                                props.closeModal();
                            }
                        }else{
                            Swal.fire({
                                text:'Não foi possivel adicionar 2 !', 
                                icon :'warning', 
                                timer : 1500, 
                                showCancelButton:false, 
                                showConfirmButton:false
                            })
                            
                        }
                        limparControlesCliente()
                        props.updateClientes()
                        props.closeModal();
                    }).catch( error =>{
                        Swal.fire({
                            text:'Não foi possivel adicionar !', 
                            icon :'error', 
                            timer : 1500, 
                            showCancelButton:false, 
                            showConfirmButton:false
                        })
                        limparControlesCliente()
                        props.updateClientes()
                        props.closeModal();
    
                        
                    })
                }else{
                    Conexao.post('clientesController/insertCliente',{
                        idusuario : props.idempresa, 
                        idempresa : props.chaveEmpresa, 
                        cnpj : cnpj, 
                        inscEstadual : inscEstadual ? inscEstadual : '',  
                        razaoSocial : razaoSocial ? razaoSocial : '', 
                        nomeFantasia : nomeFantasia ? nomeFantasia : '', 
                        endereco : endereco ? endereco : '', 
                        numero : numero ? numero : '', 
                        cep : cep ? cep : '', 
                        complemento : complemento ? complemento : '', 
                        bairro : bairro ? bairro : '',
                        cidade : cidade ? cidade : '', 
                        estado : estado ? estado : '' , 
                        telefoneCelular : telefoneCelular ? telefoneCelular : '', 
                        telefoneComercial : telefoneComercial ? telefoneComercial : '', 
                        email : email ? email : '',                         
                        codigoClienteERP : codigoClienteERP
                    }).then( response =>{
                        
                           
                            if(response.data.status.trim() == 'OK'){
                                Swal.fire({
                                    text:'Cliente adicionado com sucesso !', 
                                    icon :'success', 
                                    timer : 1500, 
                                    showCancelButton:false, 
                                    showConfirmButton:false
                                })
                                props.updateClientes()
                                props.closeModal();
                            }else{
                                Swal.fire({
                                    text:'Não foi possivel adicionar !', 
                                    icon :'warning', 
                                    timer : 1500, 
                                    showCancelButton:false, 
                                    showConfirmButton:false
                                })
                                props.updateClientes()
                               // props.closeModal();
                            }
                       
                        
                        props.updateClientes()
                       // props.closeModal();
                    }).catch( error =>{
                       
                        Swal.fire({
                            text:'Não foi possivel adicionar !', 
                            icon :'error', 
                            timer : 1500, 
                            showCancelButton:false, 
                            showConfirmButton:false
                        })
                        props.updateClientes()
                       // props.closeModal();
    
                        
                    })
                }
                
               
               
            } 
              
          })
        }
    }

    const validarForm = () =>{
        setError(false)
       
        if(cnpj == '' || razaoSocial ==''  || cidade == '' ||  estado == ''){
            
            setError(true)
            Swal.fire({
                text:'Você precisa informar todos os campos obrigatórios !', 
                icon :'warning', 
                timer : 1500, 
                showCancelButton:false, 
                showConfirmButton:false
            })
            return false;
        }
       // return false;
       return false;
    }

    const getLocaisCliente = () =>{
        if(!props.edicaoClientes.idempresa){
            return;
        }
        Conexao.get('/LocaisInstalacaoClienteController/getLocalById?idempresa='+props.edicaoClientes.idempresa+'&idcliente='+props.edicaoClientes.idcliente).then(response=>{
            setLocaisCliente(response.data.payload)
        }).catch(error =>{

        })
    }


    const getDepartamentos = () =>{
        if(!props.edicaoClientes.idcliente){
            return;
        }
        Conexao.post('departamentosController/getDepartamentos',{
            idempresa : props.edicaoClientes.idempresa, 
            idcliente : props.edicaoClientes.idcliente
        }).then(response=>{
            setDepartamentos(response.data.payload)
        }).catch(error =>{

        })
    }

    return <Fragment><Container>
    <div className={classes.root}>
     
      
      <form id='form-cadastro' className='form-cadastro' onKeyDown={(e)=>{
        if(e.keyCode == 13){
            return
        }
    }} onSubmit = {(event)=>{
        cadastrarCliente(event)
    }}>
  
    <Grid container spacing={1} style ={{marginTop:"10px"}}>
        <Grid container>
            <Grid item xs = {2} ><Typography variant='subtitle1' style ={{fontWeight :'bold'}}>CNPJ *</Typography></Grid>
            <Grid item xs = {2} >
                <TextFieldCNPJ                  
                        required
                        value={cnpj}
                        onChange = {(e)=>setCnpj(e.target.value)}
                        error={error && cnpj == ''}
                        disabled={props.edicaoClientes && props.edicaoClientes.idcliente }
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small' />
            </Grid>
        </Grid>
{
    /*

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Inscrição Estadual</Typography></Grid>
            <Grid item xs = {3} >
                <TextField                   
                        
                        value={inscEstadual}
                        onChange = {(e)=>setInscEstadual(e.target.value)}
                        error={error && cnpj == ''}
                        disabled={props.edicaoClientes && props.edicaoClientes.idcliente }
                        variant='outlined'
                        inputProps={{maxlength :14}} 
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small' />
            </Grid>
        </Grid>
*/}
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1' style ={{fontWeight :'bold'}}>Razão Social *</Typography></Grid>
            <Grid item xs = {10} >
                <TextField                   
                        
                    helperText  = {error && !razaoSocial ? 'Informe a Razão Social' : ''}
                    error={error && !razaoSocial }
                    value={razaoSocial}
                    onChange = {(e)=>setRazaoSocial(e.target.value)}
                    inputProps={{maxlength :150}} 
                    required
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1' style ={{fontWeight :'bold'}}>Nome Fantasia *</Typography></Grid>
            <Grid item xs = {6} >
                <TextField                   
                    
                    helperText  = {error && !nomeFantasia ? 'Informe o Nome Fantasia' : ''}
                    error={error && !nomeFantasia }
                    
                    value={nomeFantasia}
                    onChange = {(e)=>setNomeFantasia(e.target.value)}
                    required
                    inputProps={{maxlength :100}}                    
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>CEP</Typography></Grid>
            <Grid item xs = {2} >
            <TextField                    
                    value={cep}
                    onChange = {(e)=>setCep(e.target.value)}                
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' 
                    inputProps={{maxlength :10}}                    
                    onKeyUp = {(e)=>{
                    if(e.keyCode == 13){
                        ConexaoB.get('https://viacep.com.br/ws/'+e.target.value+'/json/ ').then( response =>{                             
                               if(!response.data.erro && response.data){
                                   setEndereco(response.data.logradouro)
                                   setComplemento(response.data.complemento)
                                   setBairro(response.data.bairro)
                                   setCidade(response.data.localidade)
                                   setEstado(response.data.uf)
                               }else{
                                   setEndereco('')
                                   setComplemento('')
                                   setBairro('')
                                   setCidade('')
                                   setEstado('')
                                Swal.fire({
                                    text:'Não existe endereço vinculado ao CEP informado !', 
                                    icon :'warning', 
                                })
                               }
                              
                            }).catch(error =>{
                                Swal.fire({
                                    text:'Não foi possível estabelecer conexão com serviço remoto', 
                                    icon :'error', 
                                })
                            })
                    }
                }}
                    />
            </Grid>
        </Grid>
       
 

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Endereço</Typography></Grid>
            <Grid item xs = {6} >
                <TextField  
                    value={endereco}                                                                          
                    onChange = {(e)=>setEndereco(e.target.value)}                                        
                    variant='outlined'
                    inputProps={{maxlength :60}} 
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Nº</Typography></Grid>
            <Grid item xs = {2} >
                <TextField                                                                            
                    onChange = {(e)=>setNumero(e.target.value)}                                        
                    value={numero}
                    variant='outlined'
                    inputProps ={{
                        maxlength : 10
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Complemento</Typography></Grid>
            <Grid item xs = {4} >
                <TextField                                                                            
                    value={complemento}
                    onChange = {(e)=>setComplemento(e.target.value)}
                    variant='outlined'
                    inputProps={{maxlength :30}} 
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>


        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Bairro</Typography></Grid>
            <Grid item xs = {6} >
                <TextField                                                                            
                   value={bairro}
                   onChange = {(e)=>setBairro(e.target.value)}
                    variant='outlined'
                    inputProps={{maxlength :60}} 
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1' style ={{fontWeight :'bold'}}>Cidade *</Typography></Grid>
            <Grid item xs = {8} >
                <TextField                                                                            
                    helperText  = {error && !cidade ? 'Informe o nome da Cidade' : ''}
                    error={error && !cidade }
                    value={cidade}
                    onChange = {(e)=>setCidade(e.target.value)}
                    required
                    InputLabelProps ={{shrink : true}} 
                    inputProps={{maxlength :150}} 
                    variant='outlined'
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1' style ={{fontWeight :'bold'}}>Estado *</Typography></Grid>
            <Grid item xs = {1} >
                <TextField                                                                            
                     helperText  = {error && !estado ? 'Informe o Estado' : ''}
                     error={error && !estado }
                     value={estado}
                     onChange = {(e)=>setEstado(e.target.value)}
                     required
                     inputProps={{
                          'size' :'2', 
                          'maxlength' :'2', 
                           maxlength : 2
                      }}
                      variant='outlined'
                     InputLabelProps ={{shrink : true}} 
                    size='small' />
            </Grid>
        </Grid>
        

      
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Telefone</Typography></Grid>
            <Grid item xs = {2} >
                <TextFieldTelefone                                                                            
                    value={telefoneComercial}                   
                    onChange = {(e)=>setTelefoneComercial(e.target.value)}
                    variant='outlined'
                    inputProps ={{
                        maxlength : 16
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>Celular</Typography></Grid>
            <Grid item xs = {2} >
                <TextFieldCelular                                                                            
                    value={telefoneCelular}
                    onChange = {(e)=>setTelefoneCelular(e.target.value)}
                    variant='outlined'
                    inputProps ={{
                        maxlength : 16
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1' style ={{fontWeight :'bold'}}>Email *</Typography></Grid>
            <Grid item xs = {10} >
                <TextFieldEmail                                                                            
                    clearError={true} 
                    required              
                    value={email}
                    onChange = {(e)=>setEmail(e.target.value)}
                    inputProps ={{
                        maxlength : 250
                    }}
                    setError = {(option)=>{
                        setError(option)
                    }}
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        {/*<Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle1'>ERP</Typography></Grid>
            <Grid item xs = {1} >
                <TextField                                                                            
                     clearError={true}               
                     value={codigoClienteERP}
                     onChange = {(e)=>setCodigoClienteERP(e.target.value)}
                     inputProps ={{
                         maxlength : 6
                     }}
                      variant='outlined'
                     InputLabelProps ={{shrink : true}} 
                    size='small' />
            </Grid>
                    </Grid>*/}



    </Grid>

    
   
    <br/>
  
    <Grid container spacing={1}>
        <Grid item xs = {4} >{props.edicaoClientes && props.edicaoClientes.idcliente && <ModalListaLocais edicaoClientes={props.edicaoClientes} titulo={props.edicaoClientes.razaosocial}/>}</Grid>
        
    <Grid item xs = {4} align={'right'}></Grid>
        <Grid item xs = {2} align={'right'}>
        <Button variant='contained' color='secondary' size='large' style={{minHeight:"50px"}} fullWidth
        size='small' disabled={loading} onClick = {()=>{ 
            Swal.fire({
                icon :'info',
                text : 'Desaja realmente cancelar ?', 
                showConfirmButton : true, 
                confirmButtonText : 'Confirmar',
                showCancelButton : true, 
                cancelButtonText : 'Cancelar'
            }).then((result)=>{
                if(result.isConfirmed){
                    limparControlesCliente();
                    props.closeModal(); 
                }
            })   
                           
                }}>Cancelar</Button>
        </Grid>
        <Grid item xs = {2} >
            <Button variant='contained' color='primary' size='large' style={{minHeight:"50px"}}  fullWidth
            size='small' disabled={loading} onClick = {()=>{
                    cadastrarCliente()
                    
                }}>Gravar</Button>
        </Grid>
    </Grid>

    </form>
     
   
     
    
    </div>
 
    </Container>
    {/*
    <Grid item xs = {2} >{props.edicaoClientes && props.edicaoClientes.idcliente && <Button color ='primary' variant='outlined' onClick={()=>setShowModal(true)}>Novo Local</Button>}</Grid>
    <ModalFull
        titulo={'Cadastro de novo Local de Instalação : ' +props.edicaoClientes.idcliente+'-'+props.edicaoClientes.razaosocial}
        showModal ={showModal}
        idempresa = {props.edicaoClientes.idempresa}
        cliente = {props.edicaoClientes}
        closeModal = {()=>setShowModal(false)}
    >
    <FormNovoLocalClientes
        cliente = {props.edicaoClientes}
        localCliente = {[]}
        closeModal = {()=>setShowModal(false)}
        updateLocalClientes = {()=>getLocaisCliente()}
        acao = 'cadastro'
    />
    </ModalFull>*/}
    </Fragment>
}

export default withRouter(FormNovoCliente)