import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {TextField, Tooltip, Grid, Divider, Switch} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import TextFieldCNPJ from '../../components/TextFieldCNPJ';
import Conexao from '../../providers/Conexao'

var md5 = require('md5');


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalAlteraRevenda = (props) => {

    const [cnpj, setCnpj] = React.useState(props.revenda.cpf_cnpj)
    const [razaoSocial, setRazaoSocial] = React.useState(props.revenda.nome)

    const [logradouro, setLogradouro] = React.useState(props.revenda.logradouro)
    const [numero, setNumero] = React.useState(props.revenda.numero)
    const [complemento, setComplemento] = React.useState(props.revenda.complemento)
    const [bairro, setBairro] = React.useState(props.revenda.bairro)
    const [cep, setCEp] = React.useState(props.revenda.cep)
    const [cidade, setCidade] = React.useState(props.revenda.cidade)
    const [estado, setEstado] = React.useState(props.revenda.estado)
    const [email, setEmail] = React.useState(props.revenda.email)

    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [erro, setErro]= React.useState(true)

    const [habilitarCobranca, setHabilitarCobranca] = React.useState(props.revenda.habilitarcobranca == 't' ? true : false)
    const [habilitarCollector, setHabilitarCollector] = React.useState(props.revenda.habilitarcollector == 't' ? true : false)
    const [habilitarInventory, setHabilitarInventory] = React.useState(props.revenda.habilitarinventory == 't' ? true : false)
    const [valorFixo, setValorFixo] = React.useState(props.revenda.valorfixo == 't' ? true : false)
    const [valorContratado, setValorContratado] = React.useState(props.revenda.valorcontratado ? props.revenda.valorcontratado : 0)


    const handleClickOpen = () =>{
    setOpen(true) 
        
        
    setCnpj(props.revenda.cpf_cnpj)
    setRazaoSocial(props.revenda.nome)
    
    setLogradouro(props.revenda.logradouro)
    setNumero(props.revenda.numero)
    setComplemento(props.revenda.complemento)
    setBairro(props.revenda.bairro)
    setCEp(props.revenda.cep)
    setCidade(props.revenda.cidade)
    setEstado(props.revenda.estado)
    setEmail(props.revenda.email)

    setHabilitarCobranca(props.revenda.habilitarcobranca == 't' ? true : false)
    setValorFixo(props.revenda.valorfixo == 't' ? true : false)
    setValorContratado(props.revenda.valorcontratado ? props.revenda.valorcontratado : 0)
    setHabilitarCollector(props.revenda.habilitarcollector == 't' ? true : false);
    setHabilitarInventory(props.revenda.habilitarinventory == 't' ? true : false); 

    }

    const handleClose = () =>{
        setOpen(false)
    }

  
  return (
    <div>
      <Tooltip title='Alterar dados empresa'>
         
            <div style={{padding :'2px', paddingTop :'5px'}} onClick={handleClickOpen}>
           {
                props.revenda.habilitarcobranca =='t' ? <span style={{cursor:'pointer',
                    border : 'solid 1px green', 
                    padding :'3px', 
                    color: 'green', 
                    borderRadius : '3px', 
                    marginTop :'5px',
                    width :'100%', 
                    height:'100%'
                }}>CONTRATO</span> : <span style={{cursor:'pointer',
                    border : 'solid 1px #ccc', 
                    padding :'3px', 
                    color: '#444', 
                    borderRadius : '3px',
                    marginTop :'5px',
                    width :'100%', 
                    height:'100%'
                }}>EM TESTE</span>
            }
        
        </div>
      </Tooltip>
      <Dialog 
        disableBackdropClick 
        disableEscapeKeyDown
        hideBackdrop
        scroll='body'
        maxWidth='md'
        fullWidth
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Alterar Dados Revenda
        </DialogTitle>
        <DialogContent dividers style={{paddingLeft : 30, paddingRight : 30}} >
            <Grid container spacing = {1} style ={{marginTop : '5px', }}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Código Revenda</Typography>
                </Grid>
                <Grid item xs={1}>
                    <TextField 
                        fullWidth
                        value={props.revenda.idusuario}                        
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style ={{marginTop : '5px', }}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>CNPJ *</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        fullWidth
                        value={cnpj}                        
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Razão Social *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
                        fullWidth
                        value={razaoSocial}
                        onChange={(e)=>setRazaoSocial(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>



            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Endereço</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
                        fullWidth
                        value={logradouro}
                        onChange={(e)=>setLogradouro(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Numero</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        value={numero}
                        onChange={(e)=>setNumero(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            maxLength: 4
                        }}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Complemento</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        fullWidth
                        value={complemento}
                        onChange={(e)=>setComplemento(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Cep</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        value={cep}
                        onChange={(e)=>setCEp(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Bairro</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
                        fullWidth
                        value={bairro}
                        onChange={(e)=>setBairro(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Cidade *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
                        fullWidth
                        value={cidade}
                        onChange={(e)=>setCidade(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>


            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Estado *</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        value={estado}
                        onChange={(e)=>setEstado(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Email *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField 
                        fullWidth
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            <br/>
            <Divider/>
            <br/>
            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' >Empresas/Filiais</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        value={props.revenda.empresas}
                       
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
           
            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' >Liberar Collector</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Switch color='secondary' checked={habilitarCollector} onChange={(e)=>{
                        
                        setHabilitarCollector(!habilitarCollector)
                    }}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' >Liberar Inventory</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Switch color='secondary' checked={habilitarInventory} onChange={(e)=>{
                       
                        setHabilitarInventory(!habilitarInventory)
                    }}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' >Ativar Cobrança</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Switch color='secondary' checked={habilitarCobranca} onChange={(e)=>{
                        if(!habilitarCobranca){
                            Swal.fire({
                                title : 'Printerconnect', 
                                text : 'Esta opção habilitará a cobrança para esta revenda', 
                                icon:'warning', 
                                showConfirmButton:true, 
                                showCancelButton : false
                            })
                        }
                        if(habilitarCobranca){
                            setValorFixo(false)
                            setValorContratado(0)
                        }
                        setHabilitarCobranca(!habilitarCobranca)
                    }}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' >Fixar Valor Único</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Switch color='secondary' checked={valorFixo} onChange={(e)=>{
                        if(!valorFixo && habilitarCobranca){
                            Swal.fire({
                                title : 'Printerconnect', 
                                text : 'Esta opção calculará o contrato por um valor fixo por máquina, não ira considerar faixas de valores', 
                                icon:'warning', 
                                showConfirmButton:true, 
                                showCancelButton : false
                            })
                        }
                        setValorFixo(!valorFixo)
                    }}/>
                    <span style={{fontSize :'10px'}}>* Esta opção desconsidera as faixas de cobrança, o valor definido será aplicado do primeiro ao último equipamento monitorado</span>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={3} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' >Valor R$ </Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        value={valorContratado}
                        onChange={(e)=>setValorContratado(e.target.value.replace(",", "."))}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            

            
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
        
             

                if(razaoSocial == ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe a Razão Social antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }


                if(cidade== ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe a Cidade antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }

                if(estado == ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe o Estado antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }

               


                Swal.fire({
                    title : 'Databit Collector', 
                    text: 'Deseja cadatrar esta nova revenda?', 
                    icon : 'question', 
                    showConfirmButton:true,
                    confirmButtonText : 'Sim', 
                    showCancelButton : true,
                    cancelButtonText: 'Não'
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        setLoading(true)
                        Conexao.post('empresasController/updateRevenda', {
                            idusuario : props.revenda.idusuario, 
                            razaoSocial : razaoSocial,       
                            cnpj : props.revenda.cpf_cnpj,
                            logradouro : logradouro, 
                            numero : numero,
                            cep : cep,
                            complemento : complemento,
                            bairro : bairro,
                            cidade : cidade,
                            estado : estado,
                            email : email,
                            habilitarCobranca : habilitarCobranca ? 't' : 'f', 
                            valorFixo : valorFixo ? 't' : 'f', 
                            valorContratado : valorContratado ? valorContratado : 0, 
                            habilitarCollector : habilitarCollector ? 't' : 'f', 
                            habilitarInventory : habilitarInventory ? 't' : 'f'
                            
                        }).then(response=>{
                            
                           if(response.status == 200 && response.data.status =='OK'){
                                Swal.fire({
                                    title : 'Databit Collector', 
                                    text: 'Informações alteradas com sucesso!', 
                                    icon : 'success', 
                                    showConfirmButton:false, 
                                    showCancelButton : false, 
                                    timer : 1000
                                })
                                if(props.getData){
                                    props.getData();
                                }
                           }else{
                                Swal.fire({
                                    title : 'Databit Collector', 
                                    text: 'Não foi possível alterar dados da empresa!', 
                                    icon : 'warning', 
                                    showConfirmButton:false, 
                                    showCancelButton : false, 
                                    timer : 1000
                                })
                           }
                           setLoading(false)
                           setOpen(false)
                        }).catch(error=>{
                            setOpen(false)
                            setLoading(false)
                            Swal.fire({
                                title : 'Databit Collector', 
                                text: 'Não foi possível alterar dados da empresa!',
                                icon : 'error', 
                                showConfirmButton:false, 
                                showCancelButton : false, 
                                timer : 1000
                            })
                        })
                    }

                })
            
          }} color="primary" variant='contained' disabled={loading}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalAlteraRevenda)