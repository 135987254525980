import React, {Fragment, useEffect, useState, useRef} from 'react'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Grid, TextField, Tooltip, Divider, Button, Typography, InputAdornment, Breadcrumbs, Link, Badge} from '@material-ui/core'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import IconeExcel from './imagens/iconeExcel.svg'
import IconeHtml from './imagens/iconeHtml.svg'
import ModalBuscaCliente from '../Clientes/ModalBuscaCliente'


const GestaoRelatorios = (props) =>{
    const [cliente, setCliente] = useState([])
    const [meses, setMeses] = useState(1)
    const refReferencia = useRef();



    return <Principal breadcrumbs={
      <Breadcrumbs aria-label="breadcrumb">  
      <Typography>Você esta em:</Typography>          
          <Link color="inherit" to={'#'} >
              Gestão de Relatórios
          </Link>                
      </Breadcrumbs>
  }>
    
           
       
            <br/>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                <TextField
                    label='Cliente' 
                    variant='outlined'
                    fullWidth
                    disabled
                    value = {cliente.razaosocial}
                    InputLabelProps={{shrink : true}}
                    InputProps ={{
                        endAdornment : <InputAdornment position='end'><ModalBuscaCliente collector={true} chaveCollector='t' updateCliente={(cliente)=>{
                          setCliente(cliente)
                        }}/></InputAdornment>
                    }}
                    />
                </Grid>
                <Grid item xs={2}>
                <TextField
                    label='Mês de referência' 
                    variant='outlined'
                    fullWidth
                    select
                    onChange={(e)=>setMeses(e.target.value)}
                    SelectProps={{native:true}}
                    value = {meses}
                    InputLabelProps={{shrink : true}}>
                      <option value={0}>Mês atual</option>
                      <option value={1}>Mês anterior</option>                      
                      <option value={2}>2 meses atrás</option>
                      <option value={3}>3 meses atrás</option>
                </TextField>
                </Grid>
            </Grid>
            <br/>
            <br/>
            {cliente.idcliente &&
            <React.Fragment>
              <Typography variant='subtitle1'>Relatórios por Cliente</Typography>
            <Grid container spacing={1}>
                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Ao Vivo</Typography>
              
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a  target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses=1'}>
                          <Tooltip title='Gerar relatório de contadores Ao Vivo em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses=1'}>
                          <Tooltip title='Gerar relatório de contadores Ao Vivo em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>* Relatorio de coleta de contadores extraídos na data de hoje (ao vivo), não incluem equipamentos em datas anteriores.</p>
                  </div>  

                </Grid>
                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Diários</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresDiarios?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento (detalhamento diário) em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresDiarios?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento (detalhamento diário) em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Fechamento Mensal</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresMensais?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/clientesController/relatorioContadoresMensais?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel&meses='+meses}>
                          <Tooltip title='Gerar relatório de fechamento em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Contadores Modelo</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioContadoresModelo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores agrupados por modelo em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioContadoresModelo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores agrupados por modelo em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Equipamentos podem não aparecer em datas onde não foram ligados!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Relatório Parque</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Todos equipamentos listados independente da última data de coleta.
                      </p>
                  </div>  
                </Grid>

               

             
            </Grid>

          
         

        </React.Fragment>

            
  }
  <Typography variant='subtitle1'>Relatórios Gerais</Typography>
     <Grid container spacing={1}>
            <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Relatório Parque (Geral)</Typography>
                 
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioContadoresAoVivo?idempresa='+props.match.params.chaveEmpresa+'&tipo=excel'}>
                          <Tooltip title='Gerar relatório de contadores de todos os equipamentos em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Todos equipamentos listados independente da última data de coleta.
                      </p>
                  </div>  
                </Grid>
                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Descobertas Pendentes</Typography>
              
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioEquipamentosDescobertos?idempresa='+props.match.params.chaveEmpresa+'&tipo=html'}>
                          <Tooltip title='Gerar relatório novas descobertas, equipamentos que a coleta ainda não foi ativada em formato web'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioEquipamentosDescobertos?idempresa='+props.match.params.chaveEmpresa+'&tipo=excel'}>
                          <Tooltip title='Gerar relatório novas descobertas, equipamentos que a coleta ainda não foi ativada em formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>* Relatorio de novos equipamentos descobertos e que ainda não tiveram a opção de coleta ativada.</p>
                  </div>  

                </Grid>

                

                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Equipamentos Desabilitados</Typography>
              
                      <div style ={{display:"flex", marginTop:"20px"}}>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioEquipamentosDesabilitados?idempresa='+props.match.params.chaveEmpresa+'&tipo=html'}>
                          <Tooltip title='Gerar relatório de equipamentos desabilitados em tela'> 
                            <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>
                          <div style = {{width:"50%"}}>
                          <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/relatorioEquipamentosDesabilitados?idempresa='+props.match.params.chaveEmpresa+'&tipo=excel'}>
                          <Tooltip title='Gerar relatório de equipamentos desabilitados em  formato xls'> 
                            <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                          </Tooltip>
                          </a>
                          </div>                          
                      </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>* Relatorio de equipamentos desabiltiados, mostra todos os equipamentos que estão com a coleta inativada.</p>
                  </div>  

                </Grid>

                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Equipamentos Monitorados</Typography>              
                        <div style ={{display:"flex", marginTop:"20px"}}>
                            <div style = {{width:"50%"}}>
                            <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/getDataStatusParqueReportFull?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=html'}>                                                        
                            <Tooltip title='Gerar relatório de equipamentos desabilitados em tela'> 
                              <img src={IconeHtml} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                            </Tooltip>
                            </a>
                            </div>
                            <div style = {{width:"50%"}}>
                            <a target='_blank' href ={'https://printerconect.com.br/api/index.php/coletasController/getDataStatusParqueReportFull?idempresa='+props.match.params.chaveEmpresa+'&idcliente='+cliente.idcliente+'&razaosocial='+cliente.razaosocial+'&tipo=excel'}>
                            <Tooltip title='Gerar relatório de equipamentos desabilitados em  formato xls'> 
                              <img src={IconeExcel} style ={{height:"80%"}} className='div-container-btn-relatorio-icon'/>
                            </Tooltip>
                            </a>
                            </div>                          
                        </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>* Relatorio de equipamentos monitorados e os que estão ativos para faturamento Databit.</p>
                  </div>  

                </Grid>


            </Grid>
    </Principal>
}

export default withRouter(GestaoRelatorios)