import logo from './logo.svg';
import './App.css';
import {Route, Switch, BrowserRouter as Router, Redirect} from 'react-router-dom'
import History from './ServiceHistory'
import {isAuthenticated} from './providers/Autenticate.js'
import {empresaAtiva} from './providers/EmpresaAtiva.js'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Login from './pages/Login/Login'
import LandingPage from './pages/LandingPage/Index'
import TemplateMain from './pages/TemplateHome/TemplateMain';
import TemplateRevenda from './pages/Template/TemplateRevenda';
import LandingClientes from './pages/web-cliente/LandingPage/Index'
import GestaoRealtoriosClientes from './pages/web-cliente/RelatoriosCollector/GestaoRelatorios'

import Principal from './pages/Principal/Principal'
import PrincipalClientes from './pages/web-cliente/Principal/Principal_original'
import MapaGeral from './components/Mapas/MapFullpage'
import MapaLocaisClientes from './pages/Clientes/MapaLocaisClientes'
import UsuariosRevenda from './pages/UsuariosRevenda/CadastroUsuariosRevenda.js'
/*

//https://github.com/mailtop/horizontal-timeline

*/

/* Telas de gestão de empresa */

import MeusDados from './pages/Empresas/MeusDados'
import CadastroEmpresas from './pages/Empresas/CadastroEmpresas'

/* Fim das telas da empresa */


/* Telas de gestão de Clientes */


import CadastroClientes from './pages/Clientes/CadastroClientes'
import CadastroLocaisClientes from './pages/Clientes/CadastroLocaisClientes'

/* Fim das telas da Clientes */


/* Telas de gestao de fornecedores */

import CadastroFornecedores from './pages/Fornecedores/CadastroFornecedores'

/* fim das telas de fornecedores */



/* Telas de gestao de Funcionarios */

import CadastroFuncionarios from './pages/Funcionarios/CadastroFuncionarios'

/* fim das telas de Funcionarios */



/* telas de gestão de Marcas, Modelos, Equipamentos */

import CadastroMarcas from './pages/Marcas/CadastroMarcas'
import CadastroModelos from './pages/Modelos/CadastroModelos'
import CadastroTipoNumeradores from './pages/TiposNumeradores/CadastroTipoNumeradores'
import CadasotrEquipamentos from './pages/Equipamentos/CadastroEquipamentos'

import CadastroConsumiveis from './pages/Consumiveis/CadastroConsumiveis'
import CadastroAcessorios from './pages/Acessorios/CadastroAcessorios';

/* fim das telas de gestao de Marcas, Modelos, Equipamentos */



// inicio da gestão printerconnect

import CadastroDca from './pages/Dca/CadastroDca'
import CadastroDcaBilling from './pages/DcaBilling/CadastroDca'
import CadastroParametros from './pages/ParametrosDCA/CadastroParametros'
import ContadoresAoVivo from './pages/ContadoresAoVivo/ContadoresAoVivo'
import GestaoColetas from './pages/ContadoresAoVivo/GestaoColetas'
import GestaoColetasDescobertas from './pages/ContadoresAoVivo/GestaoColetasDescobertas.js';
import GestaoColetasPorCliente from './pages/ContadoresAoVivo/GestaoColetasPorCliente'
import ContadoresAoVivoClientes from './pages/web-cliente/ContadoresAoVivo/ContadoresAoVivo'
import GestaoColetasCliente from './pages/web-cliente/ContadoresAoVivo/GestaoColetas'

import HomologacoesModelo from './pages/HomologacaoModelos/CadastroHomologacao'

import DashboardColetas from './pages/Dashboard/DachboardColetas'

import LicencasCollector from './pages/LicencasCollect/LicencasCollector'
import LicencasBilling from './pages/LicencasBilling/LicencasBilling'
import AlertasSuprimentos from './pages/AlertasSuprimentos/AlertasSuprimentos'
import DetalhesColeta from './pages/ContadoresAoVivo/DetalhesColeta';
import DetalhesColetaCliente from './pages/web-cliente/ContadoresAoVivo/DetalhesColeta';
import GestaoDesabilitados from './pages/ContadoresAoVivo/GestaoDesabilitados'
import AlertasAtolamento from './pages/AtolamentosCollector/Atolamentos'
import AlertasEquipamentos from './pages/AlertasCollector/Alertas'
import EventosErro from './pages/ContadoresAoVivo/EventosErro';
import AlertasManutencao from './pages/ContadoresAoVivo/AlertasManutencao';
import LogAlertasParts from './pages/ContadoresAoVivo/LogAlertasParts';
import AlertasUnidadesToner from './pages/ContadoresAoVivo/AlertasUnidadesToner';

import NovasColetasDisponiveis from './pages/ContadoresAoVivo/NovasColetasDisponiveis';

import TrocasSuprimento from './pages/TrocaSuprimentos/TrocaSuprimentos'
import TrocaSuprimentosCliente from './pages/web-cliente/TrocaSuprimentos/TrocaSuprimentos'

import TrocasSuprimentoPrematuras from './pages/TrocaSuprimentos/TrocasPrematuras'


import Dashboard from './pages/Dashboard/DachboardColetas'
import DashboardFull from './pages/Dashboard/DashboardFull'
import DashboardCliente from './pages/webClientev2/Dashboard2/DashboardCliente';
// fim da gestão printerconenct
import NaoAutorizado from './components/NotAutorized'


import ContadoresMensais from './pages/Contadores/Mensais'

import CadastroChamadosTecnicos from './pages/ChamadosTecnicos/CadastroChamadosTecnicos'
import GestaoMultiempresa from './pages/ChamadosTecnicos/GestaoMultiempresa'
import GestaoContratos from './pages/Contratos/GestaoContratos'
import MapaCadastroRegioes from './pages/Empresas/MapaCadastroRegioes'

import GestaoRelatorios from './pages/RelatoriosCollector/GestaoRelatorios'




import GestaoTermos from './pages/Contratos/InstalacaoRetirada/GestaoTermos'





import LogImpressao from './pages/web-cliente/Bilhetagem/LogImpressao/LogImpressao'

import Equipamentos from './pages/web-cliente/Equipamentos/Equipamentos'
import ChamadosCliente from './pages/web-cliente/Chamados/CadastroChamadosTecnicos'
import FilasDeImpressao from './pages/web-cliente/Bilhetagem/FilasDeImpressao/FilasDeImpressao';
import Usuarios from './pages/web-cliente/Bilhetagem/Usuarios/Usuarios';
import Computadores from './pages/web-cliente/Bilhetagem/Computadores/Computadores';

import RelatoriosBilling from './pages/web-cliente/Bilhetagem/RelatoriosBilling/RelatoriosBilling';

import TabelaErrosColeta from './pages/ParametrosErro/CadastroTabelaErros'


import TermosEntradaSaida from './pages/Contratos/InstalacaoRetirada/GestaoTermos'



import CentroDeCusto from './pages/web-cliente/Bilhetagem/CentroDeCusto/CentroDeCusto';
import CadastrarDepartamento from './pages/web-cliente/Bilhetagem/CentroDeCusto/Departamento/CadastrarDepartamento';


import AlertasAtolamentoClientes from './pages/web-cliente/AtolamentosCollector/Atolamentos-clientes';
import AlertasEquipamentosClientes from './pages/web-cliente/AlertasCollector/Alertas-clientes';
import AlertasSuprimentosClientes from './pages/web-cliente/AlertasSuprimentos/AlertasSuprimentos-clientes';
import CadastroSetores from './pages/web-cliente/Setores/CadastroSetores';

import HorizontalNav from './pages/Principal/HorizontalNavMenu'
import Produtos from './pages/Produtos/CadastroProdutos'
import Revendas from './pages/Revendas/Revendas.js'



import MapaFleet from './pages/Fleet/MapaPrincipal/ContainerMapa'
import Veiculos from './pages/Veiculos/CadastroVeiculos'
import Rastreadores from './pages/Fleet/Rastreadores/Rastreadores'


/* webClientev2 */

import InterfaceCliente from './pages/webClientev2/interfaceCliente/interfaceCliente';
import Template from './pages/webClientev2/Template/Template';
import DashboardCliente2 from './pages/webClientev2/Dashboard2/DashboardCliente';
import WebClientesEquipamentos from './pages/webClientev2/Collector/WebClientesEquipamentos';
import WebClienteRelatoriosCollector from './pages/webClientev2/RelatoriosCollector/WebClienteRelatoriosCollector';
import WebClientesDCA from './pages/webClientev2/Collector/WebClientesDCA';
import WebClienteAlertasSuprimento from './pages/webClientev2/AlertasSuprimentos/WebClienteAlertasSuprimento'
import PrintLoggerComputadores from './pages/webClientev2/Bilhetagem/Computadores/PrintLoggerComputadores';
import PrintLoggerFilas from './pages/webClientev2/Bilhetagem/FilasDeImpressao/PrintLoggerFilas';
import PrintLoggerUsuarios from './pages/webClientev2/Bilhetagem/Usuarios/PrintLoggerUsuarios';
import PrintLoggerRelatorios from './pages/webClientev2/Bilhetagem/RelatoriosBilling/PrintLoggerRelatorios';
import PrintLoggerLogs from './pages/webClientev2/Bilhetagem/LogImpressao/PrintLoggerLogs';
import DashboardColetasCliente from './pages/webClientev2/Dashboard/DashboardColetasCliente';
/* fim webclientv2 */
import GestaoColetasOffline from './pages/ContadoresAoVivo/GestaoColetasOffline.js'

import GestaoColetasDesabilitados from './pages/ContadoresAoVivo/GestaoColetasDesabilitados'
import GestaoColetasSemConexao from './pages/ContadoresAoVivo/GestaoColetasSemConexao'

import GestaoColetasRelatorios from './pages/ContadoresAoVivo/GestaoColetasRelatorios'


import DetalhesDCAConfig from './pages/Dca/DetalhesDCAConfig.js';
/*
# # # # MODUMO INVENTORY # # # #

*/

import DcaInventory from './pages/DataInventory/DcaInventory.js';
import CadastroComputadoresInventory from './pages/DataInventory/CadastroComputadoresInventory.js';
import DataInventoryDetails from './pages/DataInventory/DataInventoryDetails.js'

/* # # # # # # # # # # # # # # # */

const theme = createMuiTheme({
  typography :{
    useNextVariants : true,
    fontFamily: [
      'Nunito',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main :'#37474F',
      meio :"300", 
      light: '#0066ff',
    }, 
    secondary: {
      main: '#fdd835',
    },

    progres: {
      main: '#fdd835',
    },
    botoes: {
      color: '#f00',
    },
    success: {
      main: '#f00',
    },
    default: {
      main: '#fff',
    },
    warning: {
      main: '#ff9800',
    },
    danger: {
      main: '#f44336',
    },

  },
  
  
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    history = {History}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);





function App(props) {
  
  return <Router>
    <Switch>
      <MuiThemeProvider theme={theme}>
        <Route history = {History} path='/revenda' exact component={LandingPage}/>
        <Route history = {History} path='/databit' exact component={TemplateMain}/>
        <Route history = {TemplateRevenda} path='/datacount' exact component={TemplateRevenda}/>

        
        <Route history = {History} path='/mapa-fleet/revenda/:revenda/empresa/:empresa/:latitude/:longitude' exact component={MapaFleet}/>
        <PrivateRoute path = '/veiculos/revenda/:idempresa/empresa/:chaveEmpresa' component={Veiculos} />
        <PrivateRoute path = '/rastreadores/revenda/:idempresa/empresa/:chaveEmpresa' component={Rastreadores} />
        
        <Route history = {History} path='/nav' exact component={HorizontalNav}/>
        <Route history = {History} path='/' exact component={LandingPage}/>
        <Route history = {History} path='/401' exact component={LandingPage}/>
        <Route history = {History} path='/clientes' exact component={LandingClientes}/>
        <Route history = {History} path='/login' exact  component={Login}/>
        <Route history = {History} path='/404' exact  component={NaoAutorizado}/>

        <Route history = {History} path='/gestao-multiempresa/:idusuario/:idempresa/:latitude/:longitude/:razaosocial' exact component={GestaoMultiempresa}/>
        
        <PrivateRoute path='/home/revenda/:idempresa' component={TemplateRevenda}/>
        <PrivateRoute path='/home-clientes/revenda/:idempresa' component={PrincipalClientes}/>
        <PrivateRoute path='/usuarios-revenda' component={UsuariosRevenda}/>
        <PrivateRoute path='/minheempresa' component={MeusDados}/>
        <PrivateRoute path='/cadastro-empresa' component={CadastroEmpresas}/>
        <PrivateRoute path='/cadastro-clientes' component={CadastroClientes}/>
        <PrivateRoute path='/Cadastro_Locais_Clientes/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadastroLocaisClientes}/>
        <PrivateRoute path='/Cadastro-fornecedores' component={CadastroFornecedores}/>
        <PrivateRoute path='/Cadastro-funcionarios' component={CadastroFuncionarios}/>

        <PrivateRoute path='/cadastro_marcas/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadastroMarcas}/>
        <PrivateRoute path='/cadastro_modelos/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadastroModelos}/>
        <PrivateRoute path='/cadastro_tiponumeradores/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadastroTipoNumeradores}/>
        <PrivateRoute path='/cadastro_equipamentos/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadasotrEquipamentos}/>
        <PrivateRoute path='/cadastro_consumiveis/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadastroConsumiveis}/>
        <PrivateRoute path='/cadastro_acessorios/revenda/:idempresa/empresa/:chaveEmpresa/page/:page' component={CadastroAcessorios}/>
        <PrivateRoute path ='/dca/revenda/:idempresa/empresa/:chaveEmpresa' component={CadastroDca}/>
        <PrivateRoute path ='/DetalhesDCAConfig' component={DetalhesDCAConfig}/>
        
        <PrivateRoute path ='/dca-billing/revenda/:idempresa/empresa/:chaveEmpresa' component={CadastroDcaBilling}/>
        <PrivateRoute path ='/parametros_dca/revenda/:idempresa/empresa/:chaveEmpresa' component={CadastroParametros}/>
        <PrivateRoute path = '/contadores/revenda/:idempresa/empresa/:chaveEmpresa' component={ContadoresAoVivo} />


        <PrivateRoute path = '/gestao_contadores/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoColetas} />

        <PrivateRoute path = '/gestaoDescobertas/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoColetasDescobertas} />


        <PrivateRoute path = '/GestaoColetasOffline/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoColetasSemConexao} />
        <PrivateRoute path = '/GestaoColetasRelatorios/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoColetasRelatorios} />

        

        
        <PrivateRoute path = '/novosdispositivos/revenda/:idempresa/empresa/:chaveEmpresa' component={NovasColetasDisponiveis} />
        
        <PrivateRoute path = '/coletasCliente/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoColetasPorCliente} />
        <PrivateRoute path = '/gestao_desabilitados/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoColetasDesabilitados} />
        <PrivateRoute path = '/gestao_contadores_cliente/revenda/:idempresa/empresa/:chaveEmpresa/cliente/:idcliente' component={GestaoColetasCliente} />
        <PrivateRoute path = '/revendas' component={Revendas} />
        <PrivateRoute path = '/alertas_consumiveis/revenda/:idempresa/empresa/:chaveEmpresa' component={LogAlertasParts} />
        <PrivateRoute path = '/AlertasManutencao/revenda/:idempresa/empresa/:chaveEmpresa' component={AlertasManutencao} />
        <PrivateRoute path = '/alertas_toner/revenda/:idempresa/empresa/:chaveEmpresa' component={AlertasUnidadesToner} />
        <PrivateRoute path = '/EventosErro/revenda/:idempresa/empresa/:chaveEmpresa' component={EventosErro} />

        
        <PrivateRoute path = '/contadores_mensais/revenda/:idempresa/empresa/:chaveEmpresa' component={ContadoresMensais} />
        <PrivateRoute path = '/contadores_cliente/revenda/:idempresa/empresa/:chaveEmpresa/cliente/:idcliente' component={ContadoresAoVivoClientes} />

        
        <PrivateRoute path = '/homologacoes/revenda/:idempresa/empresa/:chaveEmpresa' component={HomologacoesModelo} />
        <PrivateRoute path = '/tabela-erros/revenda/:idempresa/empresa/:chaveEmpresa' component={TabelaErrosColeta} />
        
        <PrivateRoute path = '/dashboard_coletas/revenda/:idempresa/empresa/:chaveEmpresa' component={DashboardColetas} />
        <PrivateRoute path = '/dashboard' component={DashboardFull} />
        <PrivateRoute path = '/licencas_collect/revenda/:idempresa/empresa/:chaveEmpresa' component={LicencasCollector} />
        <PrivateRoute path = '/licencas_billing/revenda/:idempresa/empresa/:chaveEmpresa' component={LicencasBilling} />
        <PrivateRoute path = '/alertas_suprimentos/revenda/:idempresa/empresa/:chaveEmpresa' component={AlertasSuprimentos} />
        <PrivateRoute path = '/trocas_suprimento/revenda/:idempresa/empresa/:chaveEmpresa' component={TrocasSuprimento} />
        <PrivateRoute path = '/trocas_suprimento_cliente/revenda/:idempresa/empresa/:chaveEmpresa/cliente/:idcliente' component={TrocaSuprimentosCliente} />
        <PrivateRoute path = '/relatorios/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoRelatorios} />
        <PrivateRoute path = '/relatorios-cliente/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoRealtoriosClientes} />

        <PrivateRoute path = '/produtos' component={Produtos} />

        

        
        
        <PrivateRoute path = '/trocas_prematuras/revenda/:idempresa/empresa/:chaveEmpresa' component={TrocasSuprimentoPrematuras} />
        

        <PrivateRoute path = '/detalhes_Coleta/revenda/:idempresa/empresa/:chaveEmpresa/coleta/:idcoleta' component={DetalhesColeta} />
        <PrivateRoute path = '/detalhes_Coleta_cliente/revenda/:idempresa/empresa/:chaveEmpresa/coleta/:idcoleta/cliente/:idcliente' component={DetalhesColetaCliente} />

        
        <PrivateRoute path = '/grafico-clientes' component={Dashboard} />
        

        <PrivateRoute path = '/chamados-tecnicos' component={CadastroChamadosTecnicos} />
        <PrivateRoute path = '/gestao-contratos/revenda/:idempresa/empresa/:chaveEmpresa' component={GestaoContratos} />
        <PrivateRoute path = '/alertas-atolamentos' component={AlertasAtolamento} />
        <PrivateRoute path = '/alertas-equipamentos' component={AlertasEquipamentos} />
        
        <PrivateRoute path = '/mapa-empresa/revenda/:idempresa/empresa/:chaveEmpresa' component={MapaGeral} />
        <PrivateRoute path = '/cadastro-regioes/revenda/:idempresa/empresa/:chaveEmpresa' component={MapaCadastroRegioes} />

        <PrivateRoute path = '/mapa-local-clientes/revenda/:idempresa/empresa/:chaveEmpresa' component={MapaLocaisClientes} />
        

        <PrivateRoute path = '/gestao-termos' component={GestaoTermos} />


        <PrivateRoute path = '/logimpressao' component={LogImpressao} />
        <PrivateRoute path = '/equipamentos' component={Equipamentos} /> 
        <PrivateRoute path = '/cadastrochamadostecnicos' component={ChamadosCliente} />
        <PrivateRoute path = '/usuarios' component={Usuarios} />
        <PrivateRoute path = '/computadores' component={Computadores} />
        <PrivateRoute path = '/filasdeimpressao' component={FilasDeImpressao} />
        <PrivateRoute path = '/relatoriosbilling' component={RelatoriosBilling} />
        

        <PrivateRoute path = '/alertas-equipamentos-clientes' component={AlertasEquipamentosClientes} />
        <PrivateRoute path = '/alertas-atolamentos-clientes' component={AlertasAtolamentoClientes} />
        <PrivateRoute path = '/alertas-suprimentos-clientes' component={AlertasSuprimentosClientes} />



        <PrivateRoute path ='/centrodecusto' component={CentroDeCusto} />
        <PrivateRoute path ='/cadastrardepartamento' component={CadastrarDepartamento} />
        <PrivateRoute path ='/cadastrarsetores' component={CadastroSetores} />



        {/* interface client*/}
        <Route path ='/webclient/home' component={Template}/>
        <Route path ='/webclient/dashboard' component={DashboardCliente2}/>
        
        <Route path ='/webclient/equipamentos' component={WebClientesEquipamentos}/>
        <Route path ='/webclient/dcas' component={WebClientesDCA}/>

        
        <Route path ='/webclient/printlogger-computadores' component={PrintLoggerComputadores}/>        
        <Route path ='/webclient/printlogger-filas' component={PrintLoggerFilas}/>
        <Route path ='/webclient/printlogger-usuarios' component={PrintLoggerUsuarios}/>
        <Route path ='/webclient/printlogger-relatorios' component={PrintLoggerRelatorios}/>
        
        
        
         <Route path ='/webclient/printlogger-logs' component={PrintLoggerLogs}/>

         <Route path ='/webclient/relatorios-collector' component={WebClienteRelatoriosCollector}/>
         <Route path ='/webclient/alertas-suprimentos' component={WebClienteAlertasSuprimento}/>
         <PrivateRoute path = '/webclient/painel-cliente' component={DashboardColetasCliente} />


        <PrivateRoute path ='/inventory-dca/revenda/:idempresa/empresa/:chaveEmpresa' component={DcaInventory}/>
        <PrivateRoute path ='/CadastroComputadoresInventory' component={CadastroComputadoresInventory}/>
        <PrivateRoute path ='/DataInventoryDetails' component={DataInventoryDetails}/>
        

        
     
      </MuiThemeProvider>
    </Switch>
  </Router>

}

export default App;
