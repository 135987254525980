import { getToken } from "./Autenticate";
/*https://twitter.com/i/status/1782803461513359734*/

/*
https://pspdfkit.com/blog/2022/how-to-convert-html-to-pdf-using-react/

*/
var axios = require('axios');

//var server = 'fleetconnect1.websiteseguro.com'

//var server = 'printerconnect.com.br'

var md5 = getToken;


const serverPrinterconnect = 'https://datacount.com.br/webapi/index.php/';

var url = window.location.host.toLowerCase();

console.log(url)

var serverDatacount = 'https://datacount.com.br/webapi/index.php/';


const token =  md5('printerconnect#2021'); // Defina o token aqui


const axiosInstance = axios.create({
  baseURL: url == 'localhost:3000' ? serverPrinterconnect : serverDatacount,
  timeout: 30000,
  headers: {    
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`, // Adiciona o token de autenticação
    'origin' :'https://www.datacount.com.br',
    'Referer':'https://www.datacount.com.br', 
  }
});
 


  axiosInstance.interceptors.request.use(async config => {
  const token = md5('printerconnect#2021');//getToken();

  if (token) {
    config.headers.Authorization = 'Bearer '+token;
  }

  return config;

});
export default axiosInstance;
