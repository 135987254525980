import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalNovoDca from './ModalNovoDca'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'
import EditarDca from './ModalEdicaoDCA'
import ModalParametrosDca from './ModalParametrosDca';
import ContainerOrderBy from '../../components/ContainerOrderBy';
import { Filter } from 'react-ionicons';
import SideBarFiltros from '../../components/SideBarFiltros';


const CadastroDca = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();
    const [data, setData] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [mostrarInativos, setMostrarInativos] = useState(false); // Variável para mostrar ou não inativos
    const [orderBy, setOrderBy] = useState(props.location.state.orderBy ? props.location.state.orderBy : 'razaosocial'); // Estado para a coluna de ordenação
    const [orderDirection, setOrderDirection] = useState(props.location.state.orderDirection ? props.location.state.orderDirection : 'asc'); 
    const {usuario} = props.location.state

    const [chaveFilter, setChaveFilter]= useState('');
    const [criacaoFilter, setCriacaoFilter]= useState('');
    const [instalacaoFilter, setInstalacaoFilter]= useState('');
    const [atualizacaoFilter, setAtualizacaoFilter]= useState('');
    const [hostNameFilter, setHostNameFilter]= useState('');
    const [dcaSelecionado, setDcaSelecionado]= useState([]);
    const [openSideBar, setOpenSidebar]= useState(false);



    useEffect(() => {
        fetchData();
       
    }, []);

    useEffect(() => {
        filterAndPaginateData();
    }, [data, currentPage, rowsPerPage, searchQuery, mostrarInativos, orderBy, orderDirection]); // Adicionando mostrarInativos como dependência

    const fetchData = async () => {
       
        setLoading(true);
        try {
            const response = await Conexao.post('dcaController/getDcaFullData', {
                idusuario : props.match.params.idempresa, 
                idempresa : props.match.params.chaveEmpresa,    
            });

            if (response.status === 200 && response.data.status === 'OK') {

                for(let i = 0; i < response.data.payload.length; i++){
                    response.data.payload[i].dias  = calcularDiferencaDias(response.data.payload[i].dataatualizacao);
                }

                setData(response.data.payload);
                setTotalRecords(response.data.payload.length);
            } else {
                setData([]);
                setTotalRecords(0);
            }
        } catch (error) {
            setData([]);
            console.error('Erro ao buscar dados:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const filterAndPaginateData = () => {
        
        let filtered = data;

        // Inclui registros inativos se mostrarInativos for verdadeiro
        if (!mostrarInativos) {
            filtered = filtered.filter(item => item.ativo === 't');
        }

        // Aplica filtro pela razão social
        filtered = filtered.filter(item =>
            item.razaosocial.toLowerCase().includes(searchQuery.toLowerCase())
        );


        filtered.sort((a, b) => {
            const valueA = a[orderBy] || '';  // Fallback para ''
            const valueB = b[orderBy] || '';  // Fallback para ''
        
            // Checa se algum dos valores é vazio
            if (!valueA && valueB) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            if (valueA && !valueB) {
                return orderDirection === 'asc' ? -1 : 1;
            }
        
            // Se ambos os valores são vazios ou ambos não são, compara normalmente
            if (orderDirection === 'asc') {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });


        // Define totalRecords com base na quantidade de registros filtrados
        setTotalRecords(filtered.length);

        let startIndex = currentPage * rowsPerPage;
        if (startIndex < 0) {
            startIndex = 0;
        }
        const endIndex = Math.min(startIndex + rowsPerPage, filtered.length);

        setFilteredData(filtered.slice(startIndex, endIndex));
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(0);
    };

    const handleMostrarInativosChange = (event) => {
        console.log(event.target.value)
        if(event.target.value === 'Sim'){
            setMostrarInativos(true);
        }else{
            setMostrarInativos(false);
        }
       
        setCurrentPage(0); // Redefine a página atual ao alterar mostrarInativos
    };

   

    const handleOrderChange = (name, value) => {
        console.log("order by "+name+' direcao '+value)
       setOrderBy(name);
       setOrderDirection(value);
       setCurrentPage(0);

   };

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <span style={{marginTop :'5px', paddingLeft : '5px', paddingRight : '5px', border :'solid 1px #000', borderRadius : '3px'}}>Manual</span>
        }else{
            return <span style={{marginTop :'5px', paddingLeft : '5px', paddingRight : '5px', backgroundColor : '#ddd', borderRadius : '3px', color:'#000'}}>Windows-Service</span>
        }
    }

    const calcularVersaoElegivel = (versao) =>{
        let versaoElegivel = versao.split('-')
        return versaoElegivel[0].replace(/[-,.;:]/g, '');


    }

    const LinkOpen = (dataDca) =>{
        if(dataDca.dcaversao){
            let versaoDCA = dataDca.dcaversao.split('-')
            if(versaoDCA.length < 2){
          
                return false

              }else if(versaoDCA[versaoDCA.length - 1] === 'B'){
                
                return true

              }
        }else{

            return false
        }
        
  
  
        
    }

    const renderStatusDca =(diferencaEmDias, chave, ativo) =>{
    /*const dataAtual = new Date(); // Data atual
    

    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Um dia em milissegundos
    const diferencaEmMilissegundos = Math.abs(dataAtual - new Date(dataColeta)); // Diferença em milissegundos
    
    // Converte a diferença de milissegundos para dias
    const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / umDiaEmMilissegundos);
    */
    if(ativo == 'f'){
        return <span style ={{textAlign:'center', paddingLeft : '8px', paddingRight : '8px', padding:'2px', borderRadius: '5px',  backgroundColor: '#f00', color:'#fff', width: '100%'}}>{chave}</span>
     }
     if(diferencaEmDias == null){
        return <span style ={{textAlign:'center', paddingLeft : '8px', paddingRight : '8px', padding:'2px', borderRadius: '5px',  border: 'solid 1px #212121', color:'#212121', width: '100%'}}>{chave}</span>
     }
     if(diferencaEmDias <= 1){
        return <span style ={{textAlign:'center', paddingLeft : '8px', paddingRight : '8px',padding:'2px', borderRadius: '5px',  border: 'solid 1px #00796B', color:'#00796B', width: '100%'}}>{chave}</span>
     }else if(diferencaEmDias > 1  && diferencaEmDias <=3 ){
        return <span style ={{textAlign:'center', paddingLeft : '8px', paddingRight : '8px',padding:'2px', borderRadius: '5px', border: 'solid 1px #FF8F00', color:'#FF8F00', width: '100%'}}>{chave}</span>
     }else{
        return <span style ={{textAlign:'center', paddingLeft : '8px', paddingRight : '8px',padding:'2px', borderRadius: '5px',  border: 'solid 1px #B71C1C', color:'#B71C1C', width: '100%'}}>{chave}</span>
     }


    }

    const calcularDiferencaDias =(dataatualiazacao) =>{
        const dataAtual = new Date(); // Data atual       
    
        const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Um dia em milissegundos

        const diferencaEmMilissegundos = Math.abs(dataAtual - new Date(dataatualiazacao)); // Diferença em milissegundos        
       
        const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / umDiaEmMilissegundos);
        
        return diferencaEmDias;
    
    }


    const searchEquipmento = (pChave, pEndereco, pRazaoSocial, pDataCadastro, pDataAtivacao, pDataAtualizacao) => {
        // Verifica se o array de registros existe
    
     
        if (!data || !Array.isArray(data)) {
            console.log("Data is not an array or is undefined.");
            return [];
        }
       
        // Filtra os registros com base nos critérios fornecidos
        const temp = data.filter(dca => {
            const dcaChaveInstalacao = (dca.chave || "").toLowerCase().trim();
            const dcaEnderecoIP = (dca.enderecoip || "").toLowerCase().trim();
            const dcaRazaoSocial = (dca.razaosocial || "").toLowerCase().trim();
            const dcaDataCadastro = dca.datacadastro ? new Date(dca.datacadastro) : null;
            const dcaDataAtivacao = dca.dataativacao ? new Date(dca.dataativacao) : null;
            const dcaDataAtualizacao = dca.dataatualizacao ? new Date(dca.dataatualizacao) : null;
    
            const searchChave = (pChave || "").toLowerCase().trim();
            const searchEnderecoIp = (pEndereco || "").toLowerCase().trim();
            const searchRazaoSocial = (pRazaoSocial || "").toLowerCase().trim();
            const searchDataCadastro = pDataCadastro ? new Date(pDataCadastro) : null;
            const searchDataAtivacao = pDataAtivacao ? new Date(pDataAtivacao) : null;
            const searchDataAtualizacao = pDataAtualizacao ? new Date(pDataAtualizacao) : null;
    
            console.log('Comparing:', { dcaChaveInstalacao, dcaEnderecoIP, dcaRazaoSocial, dcaDataCadastro, dcaDataAtivacao, dcaDataAtualizacao });
    
            const dataCadastroMatch = !searchDataCadastro || (dcaDataCadastro && dcaDataCadastro >= searchDataCadastro);
            const dataAtivacaoMatch = !searchDataAtivacao || (dcaDataAtivacao && dcaDataAtivacao >= searchDataAtivacao);
            const dataAtualizacaoMatch = !searchDataAtualizacao || (dcaDataAtualizacao && dcaDataAtualizacao >= searchDataAtualizacao);
    
            return (
                dcaChaveInstalacao.includes(searchChave) &&
                dcaEnderecoIP.includes(searchEnderecoIp) &&
                dcaRazaoSocial.includes(searchRazaoSocial) &&
                dataCadastroMatch &&
                dataAtivacaoMatch &&
                dataAtualizacaoMatch
            );
        });
    
       
        setFilteredData(temp);
    };
    
    
    
    return <Principal atualizar = {loading} getData={fetchData} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de DCAs Collector
            </Link>                
        </Breadcrumbs>
    }>
            <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
            <Grid item xs ={11} style={{   textAlign:'right'}}>               
                    <Typography  variant='subtitle1' style ={{marginTop : '5px'}}>Linhas por página</Typography> 
                </Grid>
                <Grid item xs ={1}>
                    <TextField
                        select
                        SelectProps={{native : true}} 
                        variant='outlined'
                        size='small'
                        fullWidth 
                        value={rowsPerPage}    
                        onChange={(e)=>{
                            handleChangeRowsPerPage(e)
                            
                        }}               
                        >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                    </TextField>
                </Grid>
            </Grid>
        </div>
        
      
        
        <Grid container spacing ={0} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'20px'}}>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} fullWidth onClick={()=>setOpenSidebar(!openSideBar)}>
                <Filter
                    color={'#00000'}                    
                    height="16px"
                    width="16px"
                    />
          <Typography variant='caption' style={{marginLeft : '3px'}}> FILTRO</Typography>
          </Button></Tooltip>
          <SideBarFiltros 
            openSideBar={openSideBar} 
            setOpenSidebar ={()=>setOpenSidebar(!openSideBar)} 
            showModal={false} 
            dcaSelecionado={dcaSelecionado} 
            searchEquipmento ={(pChave1, pEndereco1, pRazaoSocial1, pDataCadastro1, pDataAtivacao1, pDataAtualizacao1)=>{
                searchEquipmento(pChave1, pEndereco1, pRazaoSocial1, pDataCadastro1, pDataAtivacao1, pDataAtualizacao1)
            }}
            setDcaSelecionado ={()=>setDcaSelecionado(!dcaSelecionado)}/>
        </Grid>
        <Grid item xs = {5} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><Typography variant='caption'>{colunaFiltro.toString().toUpperCase()}</Typography></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                
                onChange={handleSearch}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Tooltip title = 'Buscar rregistros'>
                <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{
                
                
                fetchData()
                 
            }}><SearchIcon className={loading ? 'loading-spinner icone-success' : 'icone-success'}/></Button></Tooltip>
           
        </Grid>
        
        </Grid>
       <div className='div-form-container'>
        <Typography variant='caption' style ={{fontSize : '10px', color: '#000'}}>* Só é possivel alterar congigurações de DCA´s que sejam das <strong>versões final B</strong> e ele precisa já ter sido instalado, pois a versão é definido pela instalação e não pela chave.</Typography>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  className='table table-bordered table-striped table-hover table-small table-sm'>
                <TableHead>
                 
                    <TableCell align='left' style = {{width:"10%"}}>
                        <Typography  >
                        <ContainerOrderBy 
                            title='Chave/Status' 
                            help ='Ordena a lista pela Chave de Identificação'
                            handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                            direcao = {orderDirection}
                            ativo = {orderBy}
                            campo ='chavecoletor'
                            font={10}
                            />
                        </Typography>
                    </TableCell>
                    <TableCell align='left' style = {{width:"20%"}}>
                    <Typography  >
                        <ContainerOrderBy 
                            title='Razão Social' 
                            help ='Ordena a lista pela Razão Social do cliente'
                            handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                            direcao = {orderDirection}
                            ativo = {orderBy}
                            campo ='razaosocial'
                            font={10}
                            />
                    </Typography>
                    </TableCell>       
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  >
                        <ContainerOrderBy 
                            title='Criação' 
                            help ='Ordena a lista pela Data de Criação do DCA'
                            handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                            direcao = {orderDirection}
                            ativo = {orderBy}
                            campo ='datacadastro'
                            font={10}
                            />
                    </Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography >
                        <ContainerOrderBy 
                            title='Instalação' 
                            help ='Ordena a lista pela Data de Instalação do DCA'
                            handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                            direcao = {orderDirection}
                            ativo = {orderBy}
                            campo ='dataativacao'
                            font={10}
                            />
                    </Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  >
                        <ContainerOrderBy 
                            title='Atualização' 
                            help ='Ordena a lista pela Data de Atualização do DCA'
                            handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                            direcao = {orderDirection}
                            ativo = {orderBy}
                            campo ='dataatualizacao'
                            font={10}
                            />
                    </Typography>
                    </TableCell>
                   

                    <TableCell align='center' style = {{width:"10%"}}>
                    <Typography  >
                        <ContainerOrderBy 
                            title='HostName' 
                            help ='Ordena a lista pelo Hostname'
                            handleOrderChange={(opcao, direcao)=>handleOrderChange(opcao, direcao)}
                            direcao = {orderDirection}
                            ativo = {orderBy}
                            campo ='hostname'
                            font={10}
                            />
                    </Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>
                    <Typography  ><span style = {{fontSize :'10px'}}>CONFIGURAÇÕES</span></Typography>
                    
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  ><span style = {{fontSize :'10px'}}>ENDEREÇO IP</span></Typography>
                    </TableCell>

                    <TableCell align='center' style = {{width:"8%", fontSize :'10px'}}><span style = {{fontSize :'10px'}}>EXCLUIR</span></TableCell>
                </TableHead>
                <TableBody>
                   
                   {
                       filteredData && filteredData.map(function(dca, i){
                           return <TableRow >
                           <TableCell align='left' style={{fontSize:"10px", paddingTop: '10px'}}>
                            <div style ={{width : '100%', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent : 'center', height: '100%',
                                paddingTop : '10px'
                            }}>
                            {renderStatusDca(dca.dias, dca.chave, dca.ativo)}
                            </div>
                           </TableCell>
                           <TableCell align='left' style={{fontSize:"10px"}}>{dca.razaosocial}</TableCell>
              
                            <TableCell align='center'>
                               <LabeData fontData='10px'  data = {dca.datacadastro} hora ={dca.horacadastro.substr(0,8)}  fontHora = {9}/>
                               </TableCell>
                            <TableCell align='center'>
                                <LabeData fontData='10px'  data = {dca.dataativacao && dca.dataativacao} hora ={dca.horaativacao && dca.horaativacao.substr(0,8)}  fontHora = {9}/>
                            </TableCell>
                            <TableCell align='center'>
                                <LabeData fontData='10px'  data = {dca.dataatualizacao && dca.dataatualizacao} hora ={dca.horaatualizacao && dca.horaatualizacao.substr(0,8)}  fontHora = {9}/>
                            </TableCell>
                                             
                            <TableCell align='center' style={{fontSize:"10px"}}>
                                <div>{dca.hostname}</div>
                                {renderUltimoAcesso(dca.ultimoacessovia)}
                            </TableCell>
                            <TableCell align='center' style={{fontSize:"10px"}}>  

                           
                                {
                                    LinkOpen(dca) ? <Link  to ={{pathname : '/DetalhesDCAConfig' , state:{
                                        ...props.location.state, 
                                        empresa : props.location.state.empresa, 
                                        usuario : props.location.state.usuario,
                                        dca : dca, 
                                        disabled : false,
                                        idusuario : props.match.params.idempresa, 
                                        idempresa : props.match.params.chaveEmpresa, 
                                        orderBy : orderBy, 
                                        orderDirection : orderDirection, 
                                        goBack : 'dca'
                                         }}}>
                                     <SettingsApplicationsIcon/>
                                 </Link> : <Link  to ={{pathname : '/DetalhesDCAConfig' , state:{
                                        ...props.location.state, 
                                        empresa : props.location.state.empresa, 
                                        usuario : props.location.state.usuario,
                                        dca : dca, 
                                        disabled : true, 
                                        idusuario : props.match.params.idempresa, 
                                        idempresa : props.match.params.chaveEmpresa, 
                                        orderBy : orderBy, 
                                        orderDirection : orderDirection, 
                                        goBack : 'dca'
                                         }}}>
                                     <SettingsApplicationsIcon/>
                                 </Link>
                                }  
                               
                                
                                <div>{dca.dcaversao}</div>
                            </TableCell>
                            <TableCell align='center' style={{fontSize:"10px"}}>
                            {dca.enderecoip}
                            </TableCell> 
                            <TableCell align='center' style={{fontSize:"10px"}}>
                            <Button variant='outlined' disabled={dca.dataatualizacao} color='secondary' onClick={()=>{
                                
                                Swal.fire({
                                    title : 'Datacount', 
                                    text: 'Deseja remover esta chave de coleta?', 
                                    icon : 'question', 
                                    showConfirmButton : true, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : true, 
                                    cancelButtonText : 'Não'
                                }).then(resp=>{
                                    if(resp.isConfirmed){
                                        setLoading(true)
                                        Conexao.post('dcaController/removeDca', {
                                            idusuario : dca.idusuario, 
                                            idempresa : dca.idempresa, 
                                            idcliente : dca.idcliente, 
                                            iddca : dca.iddca, 
                                
                                
                                        }).then( response =>{
                                            if(response.data.status == 'OK'){
                                                fetchData();
                                            }else{
                                                Swal.fire({
                                                    title : 'Datacount', 
                                                    text: 'Não foi possivel remover!', 
                                                    icon : 'warning', 
                                                    showConfirmButton : false, 
                                                    confirmButtonText : 'Sim', 
                                                    showCancelButton : false, 
                                                    cancelButtonText : 'Não'
                                                })
                                            }
                                            setLoading(false)
                                        }).catch( error =>{
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text: 'Não foi possivel remover!', 
                                                icon : 'error', 
                                                showConfirmButton : false, 
                                                confirmButtonText : 'Sim', 
                                                showCancelButton : false, 
                                                cancelButtonText : 'Não'
                                            })
                                            setLoading(false)
                                        });
                                    }
                                })
                            }}><RemoveIcon /></Button>
                            </TableCell> 
                             
                       </TableRow>
                       })
                   }
                   {
                       filteredData.length == 0  && <TableRow>
                       <TableCell colSpan={6} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
                </TableBody>
            </Table>
        </Grid>   
        </div>                  
    
        <div className='div-paginator-button' >
            <div style={{display :'flex', alignContent:'center',  alignItems:'center', backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Total de registros:</strong> {totalRecords}</Typography>
            </div>
            <div style ={{ display:'flex', alignItems:'center'}}>
                <TablePagination
                        component="div"
                        count={totalRecords}
                        page={currentPage}                
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage='Linhas por página'
                        rowsPerPageOptions={[]}
                        />
            </div>
            <div style={{ backgroundColor : '#f0f0f0', borderRadius :'5px', width : '200px', paddingLeft :'5px', paddingRight :'5px'}}>
            <Typography variant='caption'><strong>Order by:</strong> {orderBy}</Typography><br/>
            <Typography variant='caption'><strong>Direction:</strong> {orderDirection}</Typography>
            </div>
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroDca)