export const TOKEN_KEY = "BarenToken";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) != null ;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const login = data => {

  sessionStorage.setItem(TOKEN_KEY, data.idusuario);
  sessionStorage.setItem('empresa@printerConnect', data);

};

export const logout = () => {
  
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem('empresa@printerConnect');
  sessionStorage.removeItem('cliente@printerConnect');
  sessionStorage.removeItem('empresaAtiva@printerConnect');
  sessionStorage.removeItem('equipamentos');
  sessionStorage.removeItem('timeUltimaLeitura');
  
};